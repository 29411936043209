import {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import ReactSelect from '../../shared/components/inputs/ReactSelect';
import {useOpenPOs, useSelectedPO} from '../hooks';
import Spinner from '../../shared/components/Spinner';

export const POLookupForm = ({
  companyDropdownOptions,
  homeCompany,
  setCompanyDropdownOptions,
  setActivePurchaseOrder,
  setLookupEnabled,
  configAlert
}) => {
  const {
    orderedPOs,
    isLoading: loadingOpenPOs,
    error: openPOsErr,
    setError: setOpenPOsErr,
    fetchOpenPOs
  } = useOpenPOs();
  const {
    fetchPOByID,
    purchaseOrder,
    isLoading: loadingSelectedPO,
    error: selectedPOErr,
    setError: setSelectedPOErr
  } = useSelectedPO();
  const [projectDropdownOptions, setProjectDropdownOptions] = useState([]);
  const [PODropdownOptions, setPODropdownOptions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(''); // ReactSelect v1 doesn't support refs
  const [selectedProject, setSelectedProject] = useState(''); // Managing Company and Project select here and not container for consistency
  const [selectedPO, setSelectedPO] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const hasPageBeenRendered = useRef(false);

  // -------------------------HANDLE FETCHING ERRORS ------------------------
  useEffect(() => {
    if (!openPOsErr) return;
    const alertDismiss = () => {
      fetchOpenPOs();
      setOpenPOsErr(null);
    };
    configAlert('error', openPOsErr, alertDismiss);
  }, [openPOsErr]);

  useEffect(() => {
    if (!selectedPOErr) return;

    const alertDismiss = () => {
      setSubmitDisabled(false);
      setSelectedPOErr(null);
    };
    configAlert('error', selectedPOErr, alertDismiss);
  }, [selectedPOErr]);
  // ---------------------END HANDLE FETCHING ERRORS ------------------------

  // ---------------------FORMATIING REACT SELECT DROPDOWNS -----------------
  // React select requires a specific option format -- won't have those options until properly fetched and ordered in useOpenPOs hook
  // Set Company Dropdowns
  useEffect(() => {
    const compOptions = orderedPOs.companyList ? orderedPOs.companyList : [];

    let initialSelection;
    const formattedForSelect = [...compOptions].map(companyID => {
      if (companyID === homeCompany) initialSelection = companyID;
      return {
        label: `${companyID} - ${orderedPOs[companyID].name}`,
        value: companyID
      };
    });
    setCompanyDropdownOptions(formattedForSelect);
    setSelectedCompany(initialSelection);
  }, [orderedPOs]);

  // Set Project Dropdown
  useEffect(() => {
    const companyRepo = orderedPOs[selectedCompany];
    const projOptions = companyRepo ? companyRepo.projectList : [];

    const formattedForSelect = [...projOptions].map(projID => {
      const rawDescription = companyRepo
        ? companyRepo[projID]?.description
        : undefined;
      const description = rawDescription ? rawDescription : 'No Description';
      return {
        label: `${projID} - ${description}`,
        value: projID
      };
    });
    setProjectDropdownOptions(formattedForSelect);
  }, [selectedCompany]);

  // Set PO Dropdown
  useEffect(() => {
    // Alot of this is ugly -- is there a better way? - Avoids errors before COMP is set and on HMR
    const companyRepo = orderedPOs[selectedCompany]
      ? orderedPOs[selectedCompany]
      : {};
    const project = companyRepo[selectedProject];
    const poOptions = project ? project.poList : [];

    const formattedForSelect = [...poOptions].map(poID => {
      const selectedPO = project[poID];
      const vendor = selectedPO.vendor ? selectedPO.vendor : 'No Vendor';
      const requestor = selectedPO.requestorID
        ? selectedPO.requestorID
        : 'No Requestor Listed';
      return {
        label: `${poID} - ${vendor} - ${requestor}`,
        value: poID
      };
    });
    setPODropdownOptions(formattedForSelect);
  }, [selectedProject]);
  // ----------------END FORMATIING REACT SELECT DROPDOWNS-----------------------

  // ----------------CHANGE EVENT HANDLERS---------------------------------------
  const handleCompanyChange = reactSelectEvent => {
    if (!reactSelectEvent) return setSelectedCompany(''); // Handles User deleting selection

    setSelectedCompany(reactSelectEvent.value);
    setSelectedProject('');
    setSelectedPO('');
  };

  const handleProjectChange = reactSelectEvent => {
    if (!reactSelectEvent) return setSelectedProject(''); // Handles User deleting selection

    setSelectedProject(reactSelectEvent.value);
    setSelectedPO('');
  };

  const handlePOChange = reactSelectEvent => {
    // Handles User deleting selection
    if (!reactSelectEvent) {
      setSubmitDisabled(true);
      return setSelectedPO('');
    }

    setSelectedPO(reactSelectEvent.value);
    setSubmitDisabled(false);
  };
  // ----------------END CHANGE EVENT HANDLERS----------------------------------

  // ----------------    HANDLE SUBMIT        ----------------------------------
  // Not setting purchase order here due to asynchronous nature of fetch and setState - two async operations could make for weird bugs
  const handleSubmit = () => {
    setSubmitDisabled(true);
    fetchPOByID(selectedCompany, parseInt(selectedPO));
  };

  // Handles submit after useSelectedPO sets purchaseOrder
  useEffect(() => {
    // Preventing this from running on initial render
    if (hasPageBeenRendered.current) {
      // Given we're using lookups sourced from Epicor, this should never happen
      if (purchaseOrder.notFound) {
        const alertMsg = `PO ${selectedPO} in Company ${selectedCompany} was not found in Epicor - Dismiss alert to try again`;
        const alertDismiss = () => {
          setSubmitDisabled(false);
        };

        configAlert('warning', alertMsg, alertDismiss);
        return;
      }
      setActivePurchaseOrder(purchaseOrder);
      setLookupEnabled(false);
    }
    if (!hasPageBeenRendered.current) hasPageBeenRendered.current = true;
  }, [purchaseOrder]);
  // ----------------    END HANDLE SUBMIT     ----------------------------------

  return (
    <>
      <div className="form-container">
        <div className="form-section">
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="companySelect">
                <span>
                  <span className="text-danger">* </span>Select Company:
                </span>
              </label>
              <ReactSelect
                id="companySelect"
                name="Company"
                value={selectedCompany}
                isLoading={loadingOpenPOs}
                onChange={handleCompanyChange}
                options={companyDropdownOptions}
                placeholder="Select your Company"
                noResultsText="Company Not Found"
                required={true}
              />
            </div>

            <div className="col-md-6">
              <label htmlFor="projectSelect">
                <span>
                  <span className="text-danger">* </span>Select Project:
                </span>
              </label>
              <ReactSelect
                id="projectSelect"
                name="Project"
                value={selectedProject}
                onChange={handleProjectChange}
                options={projectDropdownOptions}
                placeholder={
                  selectedCompany
                    ? 'Select your Project'
                    : 'Select a Company First'
                }
                noResultsText="Project Not Found"
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="poSelect">
                <span>
                  <span className="text-danger">* </span>Select PO:
                </span>
              </label>
              <ReactSelect
                id="poSelect"
                name="PO"
                value={selectedPO}
                onChange={handlePOChange}
                options={PODropdownOptions}
                placeholder={
                  selectedProject
                    ? 'Select your Purchase Order Number'
                    : 'Select a Project First'
                }
                noResultsText="Purchase Order Number Not Found"
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6" />
            <button
              className="btn-primary col-md-4 col-md-offset-4"
              disabled={submitDisabled}
              onClick={handleSubmit}>
              Find Purchase Order
            </button>
          </div>
        </div>
      </div>
      {loadingSelectedPO && <Spinner />}
    </>
  );
};

POLookupForm.propTypes = {
  setActivePurchaseOrder: PropTypes.func.isRequired
};

/*
TO DO 
  - Add Requestor to Lookup Data that is passed to PO Display
  - Make PO dropdown into components so vendor and requestor can be made clear to the use
*/
