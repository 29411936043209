import {useState} from 'react';
import styled from 'styled-components';
import Glyphicon from '../../shared/components/Glyphicon';
import Tooltip from 'rc-tooltip';

import {ReceiptContainer} from '../containers/ReceiptContainer';

const PORelease = props => {
  const {
    setDisplayReceiptForm,
    recHeadCache,
    receiptDetails,
    PORelNum,
    line,
    isOpen,
    isVoid,
    dueDate,
    expectedQty,
    receivedQty,
    job,
    totalQtyExpected,
    partDescription,
    setReceiptFormStart,
    extraColFlags: {poHasMultipleProjs, poHasMultipleReqs},
    projectID,
    projDesc,
    requestorID,
    defWHseID,
    defBinID
  } = props;

  const [displayReceipts, setDisplayReceipts] = useState(false);
  const canReceive = job && isOpen && !isVoid;
  const noReceiveMsg = job ? 'Closed' : 'Job Required';
  const jobMsg = job ? job : 'None';
  const projMsg = projectID ? projectID : 'None';
  const projDescMsg = projDesc ? projDesc : 'No Description';
  const reqMsg = requestorID ? requestorID : 'None Listed';

  const handleDisplayReceipts = () => {
    setDisplayReceipts(!displayReceipts);
  };

  const displayReceiptForm = () => {
    setReceiptFormStart({
      line,
      release: PORelNum,
      totalQtyExpected,
      releaseQtyExpected: expectedQty,
      releaseQtyReceived: receivedQty,
      partDesc: partDescription,
      requestor: reqMsg,
      defWHseID,
      defBinID
    });
    setDisplayReceiptForm(true);
  };

  const allowReceiptDisplay = receiptDetails.length ? true : false;

  return (
    <>
      <tr>
        <th scope="row">
          {allowReceiptDisplay && (
            <DisplayButton
              displayReleases={displayReceipts}
              onClick={handleDisplayReceipts}>
              <Glyphicon glyph={'chevron-right'} />
            </DisplayButton>
          )}
        </th>
        <td>{PORelNum}</td>
        {poHasMultipleProjs && (
          <td>
            <Tooltip placement="top" overlay={<span>{projDescMsg}</span>}>
              <span>{projMsg}</span>
            </Tooltip>
          </td>
        )}
        <td>{jobMsg}</td>
        <td>{expectedQty}</td>
        <td>{receivedQty}</td>
        <td>{dueDate}</td>
        {poHasMultipleReqs && <td>{reqMsg}</td>}
        {canReceive && (
          <td>
            <button className="btn-secondary" onClick={displayReceiptForm}>
              Add Receipt
            </button>
          </td>
        )}
        {!canReceive && <td>{noReceiveMsg}</td>}
      </tr>
      {displayReceipts && (
        <tr>
          <ReceiptContainer
            receiptDetails={receiptDetails}
            poHasMultipleProjs={poHasMultipleProjs}
            poHasMultipleReqs={poHasMultipleReqs}
            recHeadCache={recHeadCache}
          />
        </tr>
      )}
    </>
  );
};

const DisplayButton = styled.button`
  ${props =>
    props.displayReleases
      ? 'transform: rotate(90deg)'
      : 'transform: rotate(0deg)'};
  transition: 'transform 250ms ease';
`;

export default PORelease;
