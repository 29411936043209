import {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';

import PODialog from '../components/PODialog';
import {POLookupForm} from '../components/POLookupForm';
import {ManualPOLookupForm} from '../components/ManPOLookupForm';
import {PurchaseOrderDisplay} from '../components/PurchaseOrderDisplay';
import {POLineContainer} from '../containers/POLineContainer';
import {ReceiptDialog} from '../components/ReceiptDialogue';
import {useAlert, useClosePackSlip, useSelectedPO} from '../hooks';
import ReceiptForm from '../components/ReceiptForm';

export const POContainer = ({onClose, onSave, currentUser}) => {
  const {
    epicorUserID: currentUserID,
    Name: currentUserName,
    Company: currUserHomeCompany
  } = currentUser;

  const activePOShape = {
    lines: [],
    releaseData: {},
    receiptHeads: [],
    receiptDetails: []
  };
  const [activePurchaseOrder, setActivePurchaseOrder] = useState(activePOShape);
  const [displayReceiptForm, setDisplayReceiptForm] = useState(false); // Set all the way down on the release and in the release form
  const [receiptFormStart, setReceiptFormStart] = useState({
    line: '',
    release: '',
    totalQtyExpected: '',
    releaseQtyExpected: '',
    partDesc: ''
  }); // Set all the way down on the release
  const [lookupEnabled, setLookupEnabled] = useState(true);
  const [manualLookupEnabled, setManualLookupEnabled] = useState(false);
  const [companyDropdownOptions, setCompanyDropdownOptions] = useState([]);
  const [openReceiptHeads, setOpenReceiptHeads] = useState([]);
  const [viewOpenPackSlips, setViewOpenPackSlips] = useState(false);
  const [receiptAdded, setReceiptAdded] = useState(null);
  const hasBeenRendered = useRef(false);
  const {alert, configAlert, displayAlert, alertRef} = useAlert();
  const {
    response: packSlipCloseResponse,
    setResponse: setPackSlipCloseResponse,
    isUpdating,
    error: packSlipUpdateErr,
    setError: setPackSlipUpdateErr,
    closePackSlip
  } = useClosePackSlip();

  // DO NOT Refactor lookups to use this -- let them all keep their own states
  const poLookup = useSelectedPO();

  const {
    lines,
    releaseData,
    receiptDetails,
    receiptHeads // receiptHead ID IS packingSlip
  } = activePurchaseOrder;

  const recHeadCache = receiptHeads.reduce((headCache, recHead) => {
    headCache[recHead.packingSlipNum] = recHead;
    return headCache;
  }, {});

  const {line, release, partDesc} = receiptFormStart;

  const companyOptionsLoaded = companyDropdownOptions.length ? true : false;
  const POSelected = activePurchaseOrder.PONum !== undefined;
  const heading = POSelected
    ? 'Select a Release to add a Receipt'
    : 'Find a Purchase Order';
  const title = POSelected ? 'Purchase Order Detail' : 'Purchase Order Lookup';

  // Should scroll all global alerts into view for this component
  useEffect(() => {
    alertRef.current?.scrollIntoView();
  }, [displayAlert]);

  /*---------------------------HANDLING OPEN RECEIPT HEADS------------------------*/
  // Handle Receipt HEAD CHANGES
  useEffect(() => {
    const openHeads = receiptHeads.filter(head => !head.allReceived);
    setOpenReceiptHeads(openHeads);
  }, [activePurchaseOrder]);

  //Close Receipt Head SUCCESS Handing
  useEffect(() => {
    if (!packSlipCloseResponse) return;

    const alertDismiss = () => {
      setPackSlipCloseResponse(null);
    };
    configAlert('success', packSlipCloseResponse, alertDismiss);
  }, [packSlipCloseResponse]);

  // Close Receipt Head ERROR Handling
  useEffect(() => {
    if (!packSlipUpdateErr) return;

    // Correct optimistic update if we failed
    const [receiptHeadToRestore] = receiptHeads.filter(
      receiptHead =>
        receiptHead.packingSlipNum === packSlipUpdateErr.packingSlipNum
    );

    setOpenReceiptHeads([...openReceiptHeads, receiptHeadToRestore]);

    const alertDismiss = () => {
      setPackSlipUpdateErr(null);
    };
    configAlert('error', packSlipUpdateErr.error, alertDismiss);
  }, [packSlipUpdateErr]);

  const handleCloseReceiptDialogue = () => {
    setDisplayReceiptForm(false);
  };

  const handleCloseReceiptHead = (
    company,
    packingSlipNum,
    vendorNum,
    purPointID,
    receivePersonID
  ) => {
    closePackSlip(
      company,
      packingSlipNum,
      vendorNum,
      purPointID,
      receivePersonID
    );
    setOpenReceiptHeads(
      openReceiptHeads.filter(
        rcvHead => rcvHead.packingSlipNum !== packingSlipNum
      )
    );
  };

  // DISPLAY OPEN RECEIPT HEADS FOR CLOSING
  const openPackingSlips = openReceiptHeads.map(receiptHead => {
    if (!receiptHead) return; // initial load this will be empty -- destructure will throw error
    const {packingSlipNum, companyID, vendorNum, purPointID} = receiptHead;
    return (
      <ReceiptHeadItem className="" key={packingSlipNum}>
        <span>
          {packingSlipNum} &nbsp;
          <button
            className="btn-link btn-xs"
            onClick={() =>
              handleCloseReceiptHead(
                companyID,
                packingSlipNum,
                vendorNum,
                purPointID,
                currentUserID
              )
            }>
            Mark All Received
          </button>
        </span>
      </ReceiptHeadItem>
    );
  });
  /*---------------------------END HANDLING OPEN RECEIPT HEADS------------------------*/

  /*---------------------------HANDLING OPEN RECEIPT ADDED------------------------*/
  // Refetching is far easier than optimistic updates with all this nesting and no central state management
  const {
    fetchPOByID,
    purchaseOrder,
    error: poLookupError,
    setError: setPOLookupError,
    isLoading: loadingPO,
    cancelGetPO
  } = poLookup;

  useEffect(() => {
    if (!receiptAdded) return; // Prevent on Initial Load
    const {company, PONum} = receiptAdded;

    fetchPOByID(company, parseInt(PONum));
    return () => {
      if (loadingPO) cancelGetPO();
    };
  }, [receiptAdded]);

  useEffect(() => {
    if (!poLookupError) return;

    const alertMsg = (
      <>
        <span>Receipt Add Successful BUT - Error Reloading Purchase Order</span>
        <br />
        <span>{poLookupError}</span>
        <br />
        <span>
          Dismiss this error and search again if you need to add more receipts
        </span>
      </>
    );
    const alertDismiss = () => {
      setPOLookupError(null);
      setActivePurchaseOrder(activePOShape);
      setManualLookupEnabled(false);
      setLookupEnabled(true);
      setOpenReceiptHeads([]);
      setViewOpenPackSlips(false);
    };
    configAlert('error', alertMsg, alertDismiss);
  }, [poLookupError]);

  useEffect(() => {
    if (hasBeenRendered.current) {
      setActivePurchaseOrder(purchaseOrder);
      setReceiptAdded(null);
      return;
    } // Prevent running on initial render

    if (!hasBeenRendered.current) hasBeenRendered.current = true;
  }, [purchaseOrder]);

  /*---------------------------HANDLING OPEN RECEIPT ADDED------------------------*/
  return (
    <PODialog
      heading={heading}
      onClose={onClose}
      title={title}
      alert={alert}
      displayAlert={displayAlert}
      poSelected={POSelected}
      lookupEnabled={lookupEnabled}
      setLookupEnabled={setLookupEnabled}
      companyOptionsLoaded={companyOptionsLoaded}
      manualLookupEnabled={manualLookupEnabled}
      setManualLookupEnabled={setManualLookupEnabled}>
      {lookupEnabled && (
        <POLookupForm
          companyDropdownOptions={companyDropdownOptions}
          homeCompany={currUserHomeCompany}
          setCompanyDropdownOptions={setCompanyDropdownOptions}
          setActivePurchaseOrder={setActivePurchaseOrder}
          setLookupEnabled={setLookupEnabled}
          setManualLookupEnabled={setManualLookupEnabled}
          configAlert={configAlert}
        />
      )}
      {manualLookupEnabled && (
        <ManualPOLookupForm
          companyDropdownOptions={companyDropdownOptions}
          homeCompany={currUserHomeCompany}
          setActivePurchaseOrder={setActivePurchaseOrder}
          setManualLookupEnabled={setManualLookupEnabled}
          configAlert={configAlert}
        />
      )}
      {/*TODO -> Adjust this display to transition out -- currently disappear is abrupt  */}
      {loadingPO && (
        <div className="row">
          <div className="col-md-12 text-center">Reloading PO...</div>
        </div>
      )}
      <div className="row">
        {!!openReceiptHeads.length && (
          <div className="col-md-12 text-center">
            <span className="">
              Open Packing Slip(s) --
              <button
                className="btn-link"
                onClick={() => {
                  setViewOpenPackSlips(!viewOpenPackSlips);
                }}>
                {viewOpenPackSlips ? 'Close' : 'View'}
              </button>
            </span>
          </div>
        )}
        {viewOpenPackSlips && (
          <div className="col-md-12 text-center"> {openPackingSlips}</div>
        )}
      </div>
      <div
        style={
          displayReceiptForm
            ? {
                display: 'none'
              }
            : {}
        }>
        {POSelected && (
          <PurchaseOrderDisplay
            activePurchaseOrder={activePurchaseOrder}
            setLookupEnabled={setLookupEnabled}
            setActivePurchaseOrder={setActivePurchaseOrder}
          />
        )}
        {!!lines?.length && (
          <POLineContainer
            lines={lines}
            releaseData={releaseData}
            recHeadCache={recHeadCache}
            receiptDetails={receiptDetails}
            setDisplayReceiptForm={setDisplayReceiptForm}
            setReceiptFormStart={setReceiptFormStart}
          />
        )}
      </div>
      {displayReceiptForm && (
        <ReceiptDialog
          onClose={handleCloseReceiptDialogue}
          heading={`Enter a Receipt for Line ${line} - Release ${release} - ${partDesc}`}
          title={'Receipt Entry'}
          alert={alert}
          displayAlert={displayAlert}>
          <ReceiptForm
            receiptFormStart={{
              ...receiptFormStart,
              vendorName: activePurchaseOrder.vendorName,
              vendorNum: activePurchaseOrder.vendorNum,
              vendorPurPoint: activePurchaseOrder.vendorPurPoint,
              company: activePurchaseOrder.companyID,
              companyLabel: `${activePurchaseOrder.companyID} - ${activePurchaseOrder.companyName}`,
              PONum: activePurchaseOrder.PONum
            }}
            receiptHeads={openReceiptHeads}
            configAlert={configAlert}
            currentUser={{
              epicorUserID: currentUserID,
              name: currentUserName
            }}
            onClose={handleCloseReceiptDialogue}
            setReceiptAdded={setReceiptAdded}
            closePackSlip={closePackSlip}
          />
        </ReceiptDialog>
      )}
    </PODialog>
  );
};

const ReceiptHeadItem = styled.li`
  margin: 0.5em;
`;
