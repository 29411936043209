import styled from 'styled-components';

export const ProgressBar = ({progress}) => {
  return (
    <ProgressContainer>
      {/* <ProgressBar style={{width: `${progress}%`}} /> -- AWS ENV CAN'T HANDLE THIS -- NOT SURE IF IT IS A WEAK COMPUTER PROBLEM OR AWS ENV PROBLEM SO LEAVING IT OUT */}
      <ProgressValue>{progress}%</ProgressValue>
    </ProgressContainer>
  );
};

const ProgressContainer = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ProgressBarStatus = styled.span`
  height: 1px;
  background-color: blue;
  transition: width 0.001s ease-in-out;
`;

const ProgressValue = styled.span`
  text-align: center;
  margin-top: 1px;
  font-size: x-small;
`;
