import PORelease from '../components/PORelease';

// Helpers -- Gets releases, and receipt lines associated with POLine
const filterReceiptDetails = (receiptDetails, releaseNum) =>
  receiptDetails.filter(rcv => {
    if (!rcv) return;
    return releaseNum === rcv.release;
  });

export const POReleaseContainer = ({
  releases,
  recHeadCache,
  receiptDetails,
  totalQtyExpected,
  partDescription,
  setDisplayReceiptForm,
  setReceiptFormStart,
  extraColFlags
}) => {
  const {poHasMultipleProjs, poHasMultipleReqs} = extraColFlags;
  const POReleases = releases.map((release, idx) => {
    const associatedReceipts = filterReceiptDetails(
      receiptDetails,
      parseInt(release.PORelNum)
    );
    return (
      <PORelease
        recHeadCache={recHeadCache}
        receiptDetails={associatedReceipts}
        totalQtyExpected={totalQtyExpected}
        partDescription={partDescription}
        setDisplayReceiptForm={setDisplayReceiptForm}
        setReceiptFormStart={setReceiptFormStart}
        extraColFlags={extraColFlags}
        key={`${release}-${idx}`}
        {...release}
      />
    );
  });

  return (
    <td colSpan={5}>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th scope="col" />
            <th scope="col" className="headerCell">
              Release
            </th>
            {poHasMultipleProjs && (
              <th scope="col" className="headerCell">
                Project
              </th>
            )}
            <th scope="col" className="headerCell">
              Job
            </th>
            <th scope="col" className="headerCell">
              Qty Expected
            </th>
            <th scope="col" className="headerCell">
              Qty Received
            </th>
            <th scope="col" className="headerCell">
              Due Date
            </th>
            {poHasMultipleReqs && (
              <th scope="col" className="headerCell">
                Requestor
              </th>
            )}
            <th scope="col" />
          </tr>
        </thead>
        <tbody>{POReleases}</tbody>
      </table>
    </td>
  );
};
