/* 
  Should display Part Description
  Should Display QTY expected
*/

import {useState} from 'react';
import styled from 'styled-components';

import Glyphicon from '../../shared/components/Glyphicon';
import {POReleaseContainer} from '../containers/POReleaseContainer';

const POLine = props => {
  const {
    setDisplayReceiptForm,
    setReceiptFormStart,
    releases,
    recHeadCache,
    receiptDetails,
    POLine,
    description,
    isOpen,
    isVoid,
    partDescription,
    partNum,
    totalLineQtyExpected,
    extraColFlags
  } = props;

  const [displayReleases, setDisplayReleases] = useState(false);
  const displayDescription = partDescription ? partDescription : description;
  const partNumDisplay = partNum ? partNum : 'N/A';

  const handleDisplayReleases = () => {
    setDisplayReleases(!displayReleases);
  };

  return (
    <>
      <tr>
        <th scope="row">
          <DisplayButton
            displayReleases={displayReleases}
            onClick={handleDisplayReleases}>
            <Glyphicon glyph={'chevron-right'} />
          </DisplayButton>
        </th>
        <td>{POLine}</td>
        <td>{partNumDisplay}</td>
        <td>{displayDescription}</td>
        <td>{totalLineQtyExpected}</td>
      </tr>
      {displayReleases && (
        <tr>
          <POReleaseContainer
            setDisplayReceiptForm={setDisplayReceiptForm}
            setReceiptFormStart={setReceiptFormStart}
            extraColFlags={extraColFlags}
            releases={releases}
            recHeadCache={recHeadCache}
            receiptDetails={receiptDetails}
            totalQtyExpected={totalLineQtyExpected}
            partDescription={displayDescription}
          />
        </tr>
      )}
    </>
  );
};

const POLineStyles = styled.div`
  .blue {
    color: 'blue'
`;

const DisplayButton = styled.button`
  ${props =>
    props.displayReleases
      ? 'transform: rotate(90deg)'
      : 'transform: rotate(0deg)'};
  transition: 'transform 250ms ease';
`;

// style={{transform: `rotate(${displayReleases ? '90deg' : '0deg'})`,
//           transition: 'transform 250ms ease'}}

export default POLine;
