import {useState, useRef} from 'react';
import Alert from '../../shared/components/Alert';

// This component creates a custom Alert from the BRTS shared Components and shares a function for configuring it
// See custom alert component for alert signature

export const useAlert = () => {
  const [alert, setAlert] = useState(null);
  const [displayAlert, setDisplayAlert] = useState(false);
  const alertRef = useRef(null);

  // Returns a function that will cancel a timeout
  const configAlert = (type, msg, dismissCB, duration) => {
    const availableTypes = {
      error: 'error',
      warning: 'warning',
      success: 'success'
    };

    if (!availableTypes[type])
      throw new Error('Alert type must be one of error, warning, or success');
    if (dismissCB && typeof dismissCB !== 'function')
      throw new Error('DismissCB must be a function or undefined');
    if (typeof duration !== 'undefined' && typeof duration !== 'number')
      throw new Error(
        'Duration must be undefined or the number of seconds for the alert to last'
      );

    const alertMsg = msg
      ? msg
      : 'An unspecified alert was triggered - shame the dev for hiding the details';

    const dismissFunc = () => {
      if (dismissCB) dismissCB();
      setAlert(null);
      setDisplayAlert(false);
    };
    const alertProps = {
      [type]: alertMsg,
      dismiss: dismissFunc
    };
    const alert = <Alert {...alertProps} ref={alertRef} />;
    setAlert(alert);
    let timeoutID;
    if (duration) timeoutID = setTimeout(dismissFunc, duration * 1000);
    setDisplayAlert(true);

    return () => {
      clearTimeout(timeoutID);
    };
  };

  return {alert, displayAlert, setDisplayAlert, configAlert, alertRef};
};
