import gql from 'graphql-tag';

export const GET_OPEN_POs = gql`
  query OpenPurchaseOrders {
    openPurchaseOrders {
      companyID
      companyName
      PONum
      entryPerson
      projectID
      projDesc
      vendor
      requestorID
    }
  }
`;

export const GET_PO_BY_ID = gql`
  query GetPOByID($company: String!, $PONum: Int!) {
    poById(company: $company, PONum: $PONum) {
      companyID
      companyName
      PONum
      isOpen
      isVoid
      comment
      vendorNum
      vendorName
      vendorPurPoint
      lines {
        companyID
        PONum
        POLine
        isOpen
        isVoid
        PONum
        description
        partNum
        partDescription
        totalLineQtyExpected
      }
      releaseData {
        associatedProjects
        associatedRequestors
        releases {
          PORelNum
          line
          isOpen
          isVoid
          dueDate
          expectedQty
          receivedQty
          job
          defWHseID
          defWHseDesc
          defBinID
          defBinDesc
          projectID
          projDesc
          requestorID
        }
      }
      receiptHeads {
        companyID
        PONum
        vendorNum
        purPointID
        packingSlipNum
        dateReceived
        dateArrived
        dateEntered
        enteredBy
        receivedBy
        allReceived
        comment
      }
      receiptDetails {
        companyID
        PONum
        line
        release
        job
        packingSlipNum
        packLine
        quantity
        wareHouseCode
        binNum
      }
    }
  }
`;

export const GET_WAREHOUSES = gql`
  query Warehouses($company: String!) {
    warehouses(company: $company) {
      companyID
      id
      description
    }
  }
`;

export const GET_BINS = gql`
  query Bins($company: String!, $wHouseID: String!) {
    bins(company: $company, wHouseID: $wHouseID) {
      companyID
      id
      description
    }
  }
`;

export const SAVE_RECEIPT = gql`
  mutation CreateReceipt($receipt: ReceiptInput!) {
    createReceipt(receipt: $receipt) {
      receiptHeadSuccess
      receiptDetailSuccess
      receiptDetailFail
      attachToDetailSuccess
      attachToDetailFail
    }
  }
`;

export const CLOSE_PACK_SLIP = gql`
  mutation ClosePackSlip(
    $company: String!
    $packSlipNum: String!
    $vendorNum: Int!
    $receivePersonID: String!
    $purPointID: String!
  ) {
    closePackSlip(
      company: $company
      packSlipNum: $packSlipNum
      vendorNum: $vendorNum
      receivePersonID: $receivePersonID
      purPointID: $purPointID
    )
  }
`;
