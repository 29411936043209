import {useEffect, useState} from 'react';
import {GET_OPEN_POs} from '../queries';
import {callGqlServer} from '../gqlService';

let fetchFails = 1;

export const useOpenPOs = (skipInitialRender, preLoadedOrderedPOs = {}) => {
  if (
    typeof skipInitialRender !== 'boolean' &&
    typeof skipInitialRender !== 'undefined'
  )
    throw new Error(
      'useOpenPOs requires boolean or nothing for skipInitialRender flag'
    );

  if (typeof preLoadedOrderedPOs !== 'object')
    // Really wish I had typescript for this
    throw new Error(
      'useOpenPOs requires a preLoaded object of Ordered POs or nothing'
    );

  const [openRawPOs, setRawOpenPOs] = useState([]);
  const [orderedPOs, setOrderedPOs] = useState(preLoadedOrderedPOs);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const controller = new AbortController();

  const fetchOpenPOs = async () => {
    setIsLoading(true);

    const errMsg =
      fetchFails <= 3
        ? `Problem fetching open POs on attempt ${fetchFails} -- see console and server logs for details`
        : `Something's really not right with fetching open POs -- contact GTS`;

    const fetchConfig = {
      signal: controller.signal,
      errMsg
    };

    const response = await callGqlServer(GET_OPEN_POs, {}, fetchConfig);

    let gqlData = {};
    const {data, error} = response; // real server response with error status code
    if (data) gqlData = data;
    const {errors, data: successData} = gqlData; // GraphQL data or Errors

    if (error || errors) {
      fetchFails += 1;
      const err = error ? error : errors[0].message;
      console.error('Open POs Error -> ', err);
      setError(errMsg);
    }
    if (successData?.openPurchaseOrders) {
      setOrderedPOs(orderPOs(successData.openPurchaseOrders)); // Will help dropdowns -- one fetch for all data and graphQL doesn't allow formatting on backend
      setRawOpenPOs(successData.openPurchaseOrders);
      fetchFails = 0;
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (skipInitialRender) return;

    fetchOpenPOs();

    return () => {
      controller.abort();
    };
  }, []);
  return {
    openRawPOs,
    error,
    isLoading,
    setRawOpenPOs,
    orderedPOs,
    setOrderedPOs,
    setError,
    fetchOpenPOs,
    cancelFetchOpenPOs: controller.abort
  };
};

/*
This will make
{
  companyList: [unique CompanyIDs] //isSet
  companyID: {
    name: comanyName
    projectList: [unique ProjectIds], //isSet
    projectID: {
      description: projectDescription
      poList: [unique PONums], //isSet
      PONum: {
        companyID,
        companyName,
        PONum,
        entryPerson,
        projectID,
        projDesc
        vendor,
        requestorID
      }
    }
  }
}
*/
// Wish I could do this on the backend --- sigh -- maybe we'll figure it out one day
function orderPOs(rawPOArr) {
  return rawPOArr.reduce((orderedPOs, currPO) => {
    const {companyID, companyName, PONum, projectID, projDesc} = currPO;
    if (!orderedPOs.companyList) orderedPOs.companyList = new Set();
    if (!orderedPOs[companyID]) orderedPOs[companyID] = {name: companyName};
    if (!orderedPOs[companyID].projectList)
      orderedPOs[companyID].projectList = new Set();
    if (!orderedPOs[companyID][projectID])
      orderedPOs[companyID][projectID] = {description: projDesc};
    if (!orderedPOs[companyID][projectID].poList)
      orderedPOs[companyID][projectID].poList = new Set();

    orderedPOs.companyList.add(companyID);
    orderedPOs[companyID].projectList.add(projectID);
    orderedPOs[companyID][projectID].poList.add(PONum);
    orderedPOs[companyID][projectID][PONum] = currPO;

    return orderedPOs;
  }, {});
}
