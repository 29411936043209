export const Receipt = ({
  packingSlipNum,
  packLine,
  quantity,
  dateEntered,
  enteredBy,
  wareHouseCode,
  binNum
}) => {
  const wareHouseMsg = wareHouseCode ? wareHouseCode : '-';
  const binMsg = binNum ? binNum : '-';

  return (
    <tr>
      <td>{`${packingSlipNum}/${packLine}`}</td>
      <td>{quantity}</td>
      <td>{dateEntered}</td>
      <td>{wareHouseMsg}</td>
      <td>{binMsg}</td>
      <td>{enteredBy}</td>
    </tr>
  );
};
