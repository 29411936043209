export const PurchaseOrderDisplay = ({
  activePurchaseOrder,
  setLookupEnabled,
  setActivePurchaseOrder
}) => {
  const {
    PONum,
    comment,
    companyID,
    companyName,
    isOpen,
    isVoid,
    vendorName,
    vendorNum,
    releaseData: {associatedProjects, associatedRequestors}
  } = activePurchaseOrder;

  // Because Projs (job) and requestors are attached to releases and not POs, we could have multiple
  const attachedProjsNum = associatedProjects.length;
  const attachedRequestorsNum = associatedRequestors.length;

  const projNameDisplay = !attachedProjsNum
    ? 'No Associated Project'
    : attachedProjsNum > 1
    ? 'Multiple Projects Attached - see Releases'
    : associatedProjects[0];

  const reqNameDisplay = !attachedRequestorsNum
    ? 'No Associated Requestors'
    : attachedRequestorsNum > 1
    ? 'Multiple Requestors Attached - see Releases'
    : associatedRequestors[0];

  const handleClick = () => {
    const activePOShape = {
      lines: [],
      releaseData: {},
      receiptHeads: [],
      receiptDetails: []
    };
    setActivePurchaseOrder(activePOShape);
    setLookupEnabled(true); // Could also reset lookup choices but we don't need to as Lookup form manages that internally
  };

  return (
    <div className="form-container">
      <div className="form-section">
        <div className="row">
          <h4 className="col-md-8">
            <i>
              <span>Purchase Order</span>
            </i>
            <span>: </span>
            <strong>{PONum}</strong>
          </h4>
        </div>
        <div className="row">
          <div className="col-md-6">
            <i>
              <span className="text-muted">Company: </span>
            </i>
            <div>{`${companyID} - ${companyName}`}</div>
          </div>
          <div className="col-md-6">
            <i>
              <span className="text-muted">Associated Project: </span>
            </i>
            <div>{projNameDisplay}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <i>
              <span className="text-muted">Vendor: </span>
            </i>
            <div>{vendorName}</div>
          </div>

          <div className="col-md-6">
            <i>
              <span className="text-muted">Requestor: </span>
            </i>
            <div>{reqNameDisplay}</div>
          </div>
        </div>
        {comment && (
          <div className="row">
            <div className="col-md-12 col-md-offset-1">
              <i>
                <span className="text-muted">Comments: </span>
              </i>
              <div>{comment}</div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-8" />
          <button className="col-md-2 col-md-offset-1" onClick={handleClick}>
            Search Again
          </button>
        </div>
      </div>
    </div>
  );
};
