import {useState} from 'react';
import {GET_BINS} from '../queries';
import {callGqlServer} from '../gqlService';

let fetchFails = 1;
export const useBins = () => {
  const [bins, setBins] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const controller = new AbortController();

  const fetchBins = async (company, warehouseID) => {
    try {
      if (!company || typeof company !== 'string')
        throw new Error('CompanyID is a required string for Bins lookup');
      if (!warehouseID || typeof warehouseID !== 'string')
        throw new Error('WareHouse ID is a required string for Bins lookup');
    } catch (e) {
      setError(e.message);
      return;
    }

    setIsLoading(true);

    const errMsg =
      fetchFails <= 3
        ? `Problem fetching bins on attempt ${fetchFails} -- see console and server logs for details`
        : `Something's really not right with fetching bins -- contact GTS or submit without Bin entry and let them know`;

    const fetchConfig = {
      signal: controller.signal,
      errMsg
    };

    const response = await callGqlServer(
      GET_BINS,
      {company, wHouseID: warehouseID},
      fetchConfig
    );

    let gqlData = {};
    const {data, error} = response; // real server response with error status code
    if (data) gqlData = data;
    const {errors, data: successData} = gqlData; // GraphQL data or Errors

    if (error || errors) {
      fetchFails += 1;
      const err = error ? error : errors[0].message;
      console.error('Bins Error -> ', err);
      setError(errMsg);
    }
    if (successData?.bins) {
      fetchFails = 0;
      setBins(successData.bins);
    }
    setIsLoading(false);
  };

  return {
    bins,
    setBins,
    error,
    isLoading,
    setError,
    fetchBins,
    cancelFetchBins: controller.abort
  };
};
