import {useState, useEffect, useRef} from 'react';
import moment from 'moment';
import ReactSelect from 'react-select';
import Tooltip from 'rc-tooltip';
import Spinner from '../../shared/components/Spinner';
import {DragNDrop} from '../../shared/components/drag-drop-upload';
import {useBins, useWarehouses, useCreateReceipt, useAlert} from '../hooks';

/*
    ** NOTE on quantity - Epicor does not return updated values on receipt submission - so we need to manage this in state
    Qty received will be adjusted by user input here -- if they add multiple receipts to a single release we need to manage
    those updated qty's on the release level. So we're getting release received from release component.
    We'll manage changes to that here, and send them up as needed in case user wants to create multiple receipts for a release
    Essentially we're mirroring what's happening in epicor on form submit
*/

function ReceiptForm({
  receiptFormStart,
  receiptHeads,
  configAlert,
  currentUser,
  onClose,
  setReceiptAdded,
  closePackSlip
}) {
  const {
    company,
    companyLabel,
    PONum,
    line,
    release,
    releaseQtyExpected,
    releaseQtyReceived,
    totalQtyExpected,
    vendorName,
    vendorNum,
    vendorPurPoint,
    requestor,
    defWHseID, // Default for the site of the job
    defBinID // Default for the default warehouse of the site of the job
  } = receiptFormStart; // Pulling from Various Tables -- set in Release component
  const initialFormState = {
    company,
    PONum: PONum || '', // Avoid switching from controlled to uncontrolled field
    line,
    release,
    packingSlip: '', // Value for SELECT - if entered manually usePackingSlipRef
    vendorNum,
    purPointID: vendorPurPoint,
    warehouseID: '',
    binID: '',
    receiverID: currentUser.epicorUserID
  };

  const {
    fetchBins,
    bins,
    error: binsError,
    setError: setBinsError,
    isLoading: binsAreLoading
  } = useBins();

  const {
    warehouses,
    fetchWarehouses,
    error: wHouseErr,
    setError: setWHouseErr,
    isLoading: wHousesAreLoading
  } = useWarehouses();

  const {
    receipt,
    postReceipt,
    setError: setReceiptError,
    error: receiptError,
    isSaving: isSavingReceipt
  } = useCreateReceipt();

  const {
    alert: packSlipAlert,
    displayAlert: displayPackSlipAlert,
    setDisplayAlert: setDisplayPackSlipAlert,
    configAlert: configPackSlipAlert
  } = useAlert(); // Using separate alerts for field validation

  const {
    alert: qtyAlert,
    displayAlert: displayQtyAlert,
    setDisplayAlert: setDisplayQtyAlert,
    configAlert: configQtyAlert
  } = useAlert(); // Using separate alerts for field validation

  const {
    alert: uploadAlert,
    displayAlert: displayUploadAlert,
    setDisplayAlert: setDisplayUploadAlert,
    configAlert: configUploadAlert
  } = useAlert(); // Using separate alerts for field validation

  const {
    alert: commentAlert,
    displayAlert: displayCommentAlert,
    setDisplayAlert: setDisplayCommentAlert,
    configAlert: configCommentAlert
  } = useAlert(); // Using separate alerts for field validation

  const onInitialRender = useRef(true);
  const packingSlipRef = useRef(null);
  const qtyRef = useRef(null);
  const startingQtyReceived = useRef(releaseQtyReceived); // We'll use this to reset received if user changes it more than once
  const dateRef = useRef(null);
  const legalComplianceRef = useRef(null);
  const commentRef = useRef(null);
  const closeHeadRef = useRef(false);
  const [formData, setFormData] = useState(initialFormState);
  const [useManualPackSlip, setUseManualPackSlip] = useState(false);
  const [freezePackSlip, setFreezePackSlip] = useState(false);
  const [receiptHeadOptions, setReceiptHeadOptions] = useState([]);
  const [warehouseOptions, setWarehouseOptions] = useState([]);
  const [binOptions, setBinOptions] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadWarning, setUploadWarning] = useState('');
  const [uploadError, setUploadError] = useState('');
  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    if (!receiptHeads.length) {
      setUseManualPackSlip(true); // if no open receipt heads -- no reason to select
      return () => {};
    }
    const receiptHeadChoices = receiptHeads.map(receiptHead => {
      const {packingSlipNum, dateEntered} = receiptHead;
      return {
        value: packingSlipNum,
        label: `${packingSlipNum} - ${dateEntered}`
      };
    });
    setReceiptHeadOptions(receiptHeadChoices);
  }, []);

  // -------------------------HANDLE FETCHING ERRORS -------------------
  // These two are probably simple enough to be combined -- think through it later
  useEffect(() => {
    if (!wHouseErr) return;

    const alertDismiss = () => {
      setWHouseErr(null);
      fetchWarehouses(formData.company);
    };

    return configAlert('error', wHouseErr, alertDismiss, 5);
  }, [wHouseErr]);

  useEffect(() => {
    if (!binsError) return;

    const alertDismiss = () => {
      setBinsError(null);
      fetchBins(formData.company, formData.warehouseID);
    };

    return configAlert('error', binsError, alertDismiss, 5);
  }, [binsError]);

  useEffect(() => {
    if (!receiptError) return;

    const alertDismiss = () => {
      setReceiptError(null);
    };

    return configAlert('error', receiptError, alertDismiss, 5);
  }, [receiptError]);

  // -------------------------END HANDLE FETCHING ERRORS---------------------

  // -------------------------HANDLE FIELD/UPLOAD ERRORS---------------------
  // For uploads -- if they do multi, we could have warnings and errors at the same time
  useEffect(() => {
    if (!uploadWarning) return;

    const alertDismiss = () => {
      setUploadWarning('');
      setDisplayUploadAlert(false);
    };

    configUploadAlert('warning', uploadWarning, alertDismiss);
    // setDisplayUploadAlert(true);
  }, [uploadWarning]);

  useEffect(() => {
    if (!uploadError) return;

    const alertDismiss = () => {
      setUploadError('');
      setDisplayUploadAlert(false);
    };

    configUploadAlert('error', uploadError, alertDismiss);
    // setDisplayUploadAlert(true);
  }, [uploadError]);
  // -------------------------END HANDLE FIELD/UPLOAD ERRORS---------------------

  // -----------------HANDLE FETCHING FOR DROPDOWNS AND FIELDS---------------

  // SETS WAREHOUSE SELECT OPTIONS -- Didn't want to make two hooks - hence initial render logic -- maybe we should
  useEffect(() => {
    if (onInitialRender.current) {
      onInitialRender.current = false;
      fetchWarehouses(formData.company);
    }

    let initialSelection = '';
    const wHouseOptions = warehouses.map(wHouse => {
      const {id, description} = wHouse;
      if (id === defWHseID) initialSelection = id;
      return {
        value: id,
        label: `${id} - ${description}`
      };
    });

    if (initialSelection) fetchBins(company, initialSelection);
    setWarehouseOptions(wHouseOptions);
    setFormData(prev => ({...prev, warehouseID: initialSelection})); // causes inefficent rerenders - but best way to add given fetch and React Select choice
  }, [warehouses]);

  // SETS BIN SELECT OPTIONS
  useEffect(() => {
    let initialSelection = '';
    const bnOptions = bins.map(bin => {
      const {id, description} = bin;
      if (id === defBinID) initialSelection = id;
      return {
        value: id,
        label: `${id} - ${description}`
      };
    });
    setBinOptions(bnOptions);
    setFormData(prev => ({...prev, binID: initialSelection})); // causes inefficent rerenders - but best way to add given fetch and React Select choice
  }, [bins]);

  // -----------------END HANDLE FETCHING FOR DROPDOWNS AND FIELDS------------

  // ----------------------CHANGE EVENT HANDLERS------------------------------
  // For Selecting Packing Slip
  const handlePackingSlipChange = reactSelectEvent => {
    const newSlip = packingSlipRef?.current?.value; // Will exist if user manually created

    if (formIsValid && !formData.packingSlip) setFormIsValid(false);

    if (!newSlip)
      setFormData({...formData, packingSlip: reactSelectEvent.value});

    validateForm();
  };

  const handleAutoGenPackSlip = () => {
    packingSlipRef.current.value = `${company}-${moment().format(
      'YYMMDDHHmmss'
    )}`;

    setFreezePackSlip(true); // Don't let the user alter an auto generated packing slip -- maintains unique value
    validateForm();
  };

  // Swapping for less controlled version -- leaving for future reference
  // const handlePackSlipBlur = () => {
  //   // Format for ID is `{userEntryUpTo7Chars}-${12CharTimestamp}` - Pack slip has 20 char limit - this is the best way to get unique
  //   // To control the format of this unique ID - we need to freeze the entry unless the user chooses to start over
  //   const packSlip = packingSlipRef?.current?.value
  //     ? packingSlipRef.current.value.trim()
  //     : null;

  //   if (!packSlip) {
  //     packingSlipRef.current.value = ''; // Make sure they haven't entered any white space
  //     return;
  //   } // They haven't entered data

  //   packingSlipRef.current.value = `${packSlip.slice(0, 7)}-${moment().format(
  //     'YYMMDDHHmmss'
  //   )}`;
  //   setFreezePackSlip(true);
  //   validateForm();
  // };

  const handlePackSlipBlur = () => {
    const packSlipLength = packingSlipRef.current?.value?.length;
    const packSlipLengthIsValid = packSlipLength <= 20;

    if (!packSlipLengthIsValid) {
      if (formIsValid) setFormIsValid(false);
      if (!displayPackSlipAlert) setDisplayPackSlipAlert(true);
      return;
    }

    validateForm();
  };

  const handleManualPackSlipChange = () => {
    const packSlipLength = packingSlipRef?.current?.value?.length;
    const packSlipLengthIsValid = packSlipLength <= 20;

    if (displayPackSlipAlert && packSlipLengthIsValid)
      return setDisplayPackSlipAlert(false);

    if (packSlipLengthIsValid) return;

    const packSlipMsg = `Over char limit: ${packSlipLength}/20 - Pack slips must be unique and under 20 chars`;

    const dismissAlert = () => {
      setDisplayPackSlipAlert(false);
    };

    if (formIsValid) setFormIsValid(false);
    configPackSlipAlert('error', packSlipMsg, dismissAlert);
  };

  const handleClearPackSlip = () => {
    packingSlipRef.current.value = '';
    if (formIsValid) setFormIsValid(false);
    setFreezePackSlip(false);
  };

  const handleDateChange = () => {
    if (!dateRef.current.value && formIsValid) setFormIsValid(false); // If they delete the value on a valid form

    validateForm();
  };

  const handleQtyChange = () => {
    const newQty = parseInt(qtyRef.current.value);
    if ((displayQtyAlert || !newQty) && formIsValid) setFormIsValid(false);

    const startingQty = startingQtyReceived.current;


    if (displayQtyAlert) setDisplayQtyAlert(false);

    if (!newQty && newQty !== 0) return; // Field is empty

    const availableQtyRemaining = releaseQtyExpected - startingQty; // The startingQtyReceived is all that's relevant until form submitted

    if (newQty <= 0 || newQty > availableQtyRemaining) {
      const errMsg =
        newQty <= 0
          ? 'Zero or negative qty is simply silly - positive #s only please'
          : `${newQty} exceeds remaining avail of ${availableQtyRemaining}`;

      const dismissErr = () => {
        qtyRef.current.value = '';
        setDisplayQtyAlert(false);
      };

      configQtyAlert('error', errMsg, dismissErr);
      // setDisplayQtyAlert(true);
      if (formIsValid) setFormIsValid(false);
      return;
    }

    validateForm();
  };

  const handleWarehouseChange = reactSelectEvent => {
    // Handle User Deletes Selection
    if (!reactSelectEvent) {
      setBinOptions([]);
      return setFormData(prev => ({
        ...prev,
        warehouseID: ''
      }));
    }
    const warehouseID = reactSelectEvent.value;
    fetchBins(formData.company, warehouseID);
    setFormData(prev => ({
      ...prev,
      warehouseID
    }));
  };

  const handleBinChange = reactSelectEvent => {
    if (!reactSelectEvent)
      return setFormData(prev => ({
        ...prev,
        binID: ''
      })); // Handle User Deletes Selection

    const binID = reactSelectEvent.value;
    setFormData(prev => ({
      ...prev,
      binID
    }));
  };

  const handleCommentChange = () => {
    const commentLength = commentRef.current.value.length;
    const commentLengthIsValid = commentLength <= 1000;

    // Should remove alert if user shortens entry without dismissing alert
    if (displayCommentAlert && commentLengthIsValid)
      return setDisplayCommentAlert(false);

    if (commentLengthIsValid) return;

    const commentMsg = `Over character limit: ${commentLength}/1,000`;

    const dismissAlert = () => {
      setDisplayCommentAlert(false);
    };

    configCommentAlert('error', commentMsg, dismissAlert);
  };

  const handleCommentBlur = () => {
    const comment = commentRef.current.value;
    const commentLength = comment.length;

    if (commentLength < 1000) commentRef.current.value = comment.slice(0, 1001); // User won't be able to see this
  };
  // ----------------END CHANGE EVENT HANDLERS-----------------------------------

  // ----------------VALIDATE AND SUBMIT-----------------------------------------
  // setUploaded files is async so we'll need to validate AFTER the new array from dragNdrop has finished that state update
  useEffect(() => {
    validateForm();
  }, [uploadedFiles]);

  useEffect(() => {
    validateForm();
  }, [formData]);

  const notifyFormInvalid = () => {
    if (formIsValid) return;
    if (validateForm()) {
      setFormIsValid(true);
      return;
    }
    const alertMsg =
      'Required Fields not valid. Check that you have filled out all fields marked with * and that you have either checked the legal attestation OR uploaded packing list documents';

    const dismissAlert = () => {};

    configAlert('error', alertMsg, dismissAlert, 10);
  };

  const validateForm = isSubmitting => {
    const hasUploads = uploadedFiles.length > 0;
    const packSlipLength = packingSlipRef.current?.value?.length;
    const packSlipLengthIsValid = packSlipLength <= 20;

    const packingSlipIsValid =
      formData.packingSlip ||
      (packingSlipRef.current?.value && packSlipLengthIsValid);
    const qtyIsValid = !displayQtyAlert && qtyRef.current?.value;
    const dateIsValid = dateRef.current?.value;
    const legalIsValid = legalComplianceRef.current?.checked || hasUploads;
    const uploadsValid = hasUploads ? !uploadError : true;
    const warehouseValid = formData.warehouseID;
    const binValid = formData.binID;

    const allValid =
      packingSlipIsValid &&
      qtyIsValid &&
      dateIsValid &&
      legalIsValid &&
      warehouseValid &&
      binValid &&
      uploadsValid;

    if (allValid) {
      if (!formIsValid && !isSubmitting) setFormIsValid(true);
      return true;
    }
    if (!allValid && formIsValid) setFormIsValid(false); // Avoids state changes when not necessary
    return false;
  };

  const handleSubmitAndCloseHead = e => {
    closeHeadRef.current = true;
    handleSubmit(e);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setFormIsValid(false);
    const hasAttachments = uploadedFiles.length > 0;
    let filesStillProcessing = 0;
    let attachmentsToSend = [];

    // Trim uploads for Server
    if (hasAttachments) {
      attachmentsToSend = uploadedFiles.map(currFile => {
        const {isProcessing, blob, extension, encodedData} = currFile;
        if (isProcessing) filesStillProcessing += 1;
        return {
          fileName: blob?.name,
          extension,
          data: encodedData
        };
      });
    }

    const dismissAlert = () => {
      setFormIsValid(true);
    };

    if (filesStillProcessing > 0) {
      const errMsg = `${filesStillProcessing} files(s) still uploading when you tried to submit. Check progress and try again when it's all clear.`;

      return configAlert('warning', errMsg, dismissAlert, 10); // Could cause memory leak for 3 seconds if user leaves
    }

    // Submit should be disabled if form not valid -- but we'll check here again just to be sure
    if (!validateForm(true)) {
      const errMsg =
        'Form is missing required field(s). Please check all marked with * and that you have either uploaded files or checked the legal attestation, and dismiss this message';

      return configAlert('warning', errMsg, dismissAlert); // No duration to avoid 5 second memory leak
    }

    // Prepare form Data for server
    const selectedDate = dateRef.current.value;

    // Need to account for two scenarios -- packing slip could have been selected or entered new - Entered New uses ref, selected uses state
    // If user enters new -- it means they rejected selecting an existing open slip so we can discount the state value in that scenario

    const currCommentValue = commentRef.current.value
      ? commentRef.current.value
      : '';
    const comment =
      currCommentValue.length <= 1000
        ? currCommentValue
        : currCommentValue.slice(0, 1001);
    const packSlipRefVal = packingSlipRef?.current?.value;
    const packingSlip = packSlipRefVal ? packSlipRefVal : formData.packingSlip;

    const formSubmission = {
      ...formData,
      qtyReceived: qtyRef.current.value,
      dateReceived: selectedDate,
      attachments: attachmentsToSend,
      comment,
      packingSlip, // will overwrite state value with either ref val or state val
      addHead: useManualPackSlip, // dictates whether or not to create a new Head on the server - manual pack slip means we created a new one rather than select an existing
      addDetailAttachment: hasAttachments
    };

    postReceipt(formSubmission);
    setFormIsValid(false);
  };

  // HANDLES SUCCESSFUL RECEIPT POST -- There's got to be a better way to do this
  useEffect(() => {
    if (!receipt) return () => {};

    // See useCreateReceipt hook for breakdown of scenarios - anything other than head fail could be partial success
    const {
      receiptHeadSuccess,
      receiptDetailSuccess,
      receiptDetailFail,
      attachToDetailSuccess,
      attachToDetailFail
    } = receipt;

    const attemptedToAddRcvHead = useManualPackSlip;
    const attemptedToAddAttachment = uploadedFiles.length > 0;

    let errors = 0;
    const errMsgs = [];
    let successes = 0;
    const successMsgs = [];

    // Will short circuit before we get hear on createHeadFail -- receiptError will handle
    if (attemptedToAddRcvHead) {
      successes += 1;
      successMsgs.push(
        <>
          <span>{`SUCCESS: ${receiptHeadSuccess}`}</span>
          <br />
        </>
      );
    }

    if (receiptDetailSuccess) {
      successes += 1;
      successMsgs.push(
        <>
          <span>{`SUCCESS: ${receiptDetailSuccess}`}</span>
          <br />
        </>
      );
    }

    if (receiptDetailFail) {
      errors += 1;
      errMsgs.push(
        <>
          <span>{`FAILED: ${receiptDetailFail}`}</span>
          <br />
        </>
      );
    }

    if (attemptedToAddAttachment) {
      attachToDetailSuccess?.forEach(success => {
        success += 1;
        successMsgs.push(
          <>
            <span>{`SUCCESS: ${success}`}</span>
            <br />
          </>
        );
      });
      attachToDetailFail?.forEach(fail => {
        errors += 1;
        errMsgs.push(
          <>
            <span>{`FAIL: ${fail}`}</span>
            <br />
          </>
        );
      });
    }

    if (!errors) {
      const alertMsg = (
        <>
          <span>Receipt Creation Fully Successful:</span>
          <br />
          {successMsgs}
        </>
      );
      const dismissAlert = () => {};
      if (closeHeadRef.current) {
        const {vendorNum, purPointID, receiverID, packingSlip} = formData;

        const packSlipRefVal = packingSlipRef?.current?.value;
        const finalPackSlipNum = packSlipRefVal ? packSlipRefVal : packingSlip;

        closePackSlip(
          company,
          finalPackSlipNum,
          vendorNum,
          purPointID,
          receiverID
        );
      }
      setReceiptAdded({company, PONum});
      onClose();
      configAlert('success', alertMsg, dismissAlert, 5);
      return; // Config returns function that clears alert duration - as this will unmount component, allowing the 5 second memory leak so the alert self dismisses
    }

    // Should really only happen if receipt detail creation fails and we weren't trying to create a receipt head
    if (!successes) {
      const alertMsg = (
        <>
          <span>Receipt Creation Failed - try again</span>
          <br />
          {errMsgs}
        </>
      );
      const dismissAlert = () => {
        setFormIsValid(true);
      };
      return configAlert('error', alertMsg, dismissAlert, 5);
    }

    // Should really only happen if only some or all attachments fail
    const detailCreated = receiptDetailSuccess ? true : false;
    const alertMsg = (
      <>
        <span>Receipt Creation Partially Successful</span>
        <br />
        <span>Successes:</span>
        <br />
        {successMsgs}
        <br />
        <span>Fails</span>
        <br />
        {errMsgs}
        {detailCreated && (
          <div>
            If only your attachment failed - it needs to be added directly in
            Epicor
          </div>
        )}
      </>
    );

    const dismissAlert = () => {
      if (receiptDetailSuccess) {
        if (closeHeadRef.current) {
          const {vendorNum, purPointID, receiverID, packingSlip} = formData;

          const packSlipRefVal = packingSlipRef?.current?.value;
          const finalPackSlipNum = packSlipRefVal
            ? packSlipRefVal
            : packingSlip;

          closePackSlip(
            company,
            finalPackSlipNum,
            vendorNum,
            purPointID,
            receiverID
          );
        }
        setReceiptAdded({company, PONum});
        onClose();
      } // All that's left would be attachments and can't redo that just yet
    };
    return configAlert('warning', alertMsg, dismissAlert, 5);
  }, [receipt]);
  // ---------------- END VALIDATE AND SUBMIT-------------------------------------

  return (
    <div className="form-container">
      <div className="form-section">
        <h5>Receipt Entry</h5>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="company">Company</label>
            <input
              type="text"
              className="form-control"
              id="company"
              name="company"
              value={companyLabel}
              disabled
              required
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="purchaseOrder">Purchase Order</label>
            <input
              type="text"
              className="form-control"
              id="purchaseOrder"
              name="purchaseOrder"
              value={PONum}
              disabled
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="vendor">Supplier</label>
            <input
              type="text"
              className="form-control"
              id="vendor"
              name="vendor"
              value={vendorName}
              disabled
              required
            />
          </div>
          {useManualPackSlip && (
            <>
              <div className="col-md-5">
                <Tooltip
                  placement="top"
                  overlay={
                    <span>
                      {
                        'Enter up to 20 characters or auto generate to create a new packing slip'
                      }
                    </span>
                  }>
                  <label htmlFor="packSlip">
                    <span className="text-danger">* </span>Packing Slip Number
                  </label>
                </Tooltip>
                <input
                  type="text"
                  className="form-control"
                  id="packSlip"
                  name="packSlip"
                  ref={packingSlipRef}
                  placeholder={`Enter or Auto Generate a number`}
                  onChange={handleManualPackSlipChange}
                  onBlur={handlePackSlipBlur}
                  disabled={freezePackSlip}
                />
                {displayPackSlipAlert && packSlipAlert}
              </div>

              {!freezePackSlip && (
                <div className="col-md-1">
                  <Tooltip
                    placement="top"
                    overlay={
                      <span>
                        {
                          "Auto generate a pack slip number if you don't have one"
                        }
                      </span>
                    }>
                    <label>Auto</label>
                  </Tooltip>
                  <button
                    className="btn btn-secondary"
                    onClick={handleAutoGenPackSlip}>
                    +
                  </button>
                </div>
              )}
              {freezePackSlip && (
                <div className="col-md-1">
                  <Tooltip
                    placement="top"
                    overlay={
                      <span>
                        Clear your pack slip number to create a new one
                      </span>
                    }>
                    <label>Clear</label>
                  </Tooltip>
                  <button
                    className="btn btn-secondary"
                    onClick={handleClearPackSlip}>
                    x
                  </button>
                </div>
              )}
            </>
          )}
          {!useManualPackSlip && (
            <>
              <div className="col-md-5">
                <Tooltip
                  placement="top"
                  overlay={
                    <span>
                      {
                        'Select an existing packing slip or create a new one by clicking the button'
                      }
                    </span>
                  }>
                  <label htmlFor="packSlip">
                    <span className="text-danger">* </span>Packing Slip Number
                  </label>
                </Tooltip>
                <ReactSelect
                  id="packSlip"
                  name="packSlip"
                  placeholder="Choose existing or add new"
                  onChange={handlePackingSlipChange}
                  options={receiptHeadOptions}
                  value={formData.packingSlip}
                />
              </div>
              <div className="col-md-1">
                <label>New</label>
                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    setFormData({
                      ...formData,
                      packingSlip: ''
                    });
                    setUseManualPackSlip(true);
                  }}>
                  +
                </button>
              </div>
            </>
          )}
        </div>
        <div className="row">
          <div className="col-md-3">
            <Tooltip
              placement="left"
              overlay={
                <span>{`Total Line Qty Expected: ${totalQtyExpected}`}</span>
              }>
              <label htmlFor="qtyExpected">Release Qty Expected</label>
            </Tooltip>
            <input
              type="text"
              className="form-control"
              id="qtyExpected"
              name="qtyExpected"
              value={releaseQtyExpected}
              disabled
              required
            />
          </div>
          <div className="col-md-3">
            <Tooltip
              placeme="right"
              overlay={
                <>
                  <span>{`Release Qty Received: ${releaseQtyReceived}`}</span>
                  <br />
                  <span>
                    {`Release Qty Remaining: ${releaseQtyExpected -
                      releaseQtyReceived}`}
                  </span>
                </>
              }>
              <label htmlFor="qtyReceived">
                <span className="text-danger">* </span>Qty to Receive (in EA)
              </label>
            </Tooltip>
            <input
              type="text"
              className="form-control"
              id="qtyReceived"
              name="qtyReceived"
              ref={qtyRef}
              onChange={handleQtyChange}
              required
            />
            {displayQtyAlert && qtyAlert}
          </div>

          <div className="col-md-6">
            <label htmlFor={'receivedDate'}>
              <span className="text-danger">* </span>Date Received
            </label>
            <input
              type="date"
              className="form-control"
              id="receivedDate"
              name="receivedDate"
              ref={dateRef}
              onChange={handleDateChange}
              defaultValue={toDateInputValue(new Date())}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Tooltip
              placement="top"
              overlay={
                <span>
                  Not sure? Use the default. No Default? Just pick one, it's
                  required
                </span>
              }>
              <label htmlFor="warehouse">
                <span className="text-danger">* </span>Warehouse
              </label>
            </Tooltip>
            <ReactSelect
              id="warehouse"
              name="warehouse"
              isLoading={wHousesAreLoading}
              placeholder="Required: Select a Warehouse"
              value={formData.warehouseID}
              options={warehouseOptions}
              onChange={handleWarehouseChange}
              required
            />
          </div>
          <div className="col-md-6">
            <Tooltip
              placement="top"
              overlay={
                <span>
                  Not sure? Use the default. No Default? Just pick one, it's
                  required
                </span>
              }>
              <label htmlFor="bin">
                <span className="text-danger">* </span>Bin
              </label>
            </Tooltip>
            <ReactSelect
              id="bin"
              name="bin"
              isLoading={binsAreLoading}
              value={formData.binID}
              options={binOptions}
              onChange={handleBinChange}
              // onChange={handleProjectChange}
              // options={projectDropdownOptions}
              placeholder={
                formData.warehouseID
                  ? 'Required: Select a Bin'
                  : 'Select a Warehouse First'
              }
              noResultsText="Bin Not Found"
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Tooltip
              placement="top"
              overlay={
                <span>
                  Comments only allowed when creating new packing slip
                </span>
              }>
              <label htmlFor="qtyExpected">Comment</label>
            </Tooltip>
            <textarea
              className="form-control"
              placeholder="Enter up to 1,000 characters"
              ref={commentRef}
              disabled={!useManualPackSlip}
              onChange={handleCommentChange}
              onBlur={handleCommentBlur}
            />
            {displayCommentAlert && commentAlert}
          </div>
          <div className="col-md-6">
            <DragNDrop
              onFilesSelected={setUploadedFiles}
              onWarningMessage={setUploadWarning}
              onErrorMessage={setUploadError}
              acceptedFileTypes={[
                '.pdf',
                '.jpg',
                '.png',
                '.docx',
                '.xlsx',
                '.csv',
                '.txt'
              ]}
              totalMaxSizeInMB={20}
              // fileMaxSizeInMB={10}
              dropMsg={'Drag or Select Receipt Documents'}
              multiple={true}
              id={'fileUpload'}
              useBase64={true}
            />
            {displayUploadAlert && uploadAlert}
          </div>
        </div>
      </div>
      {/*Attest Section */}
      <div className="form-section">
        <h5>Receiver Information</h5>
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="requestor">Requestor</label>
            <input
              type="text"
              className="form-control"
              id="requestor"
              name="requestor"
              value={requestor}
              disabled
              required
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="receiverID">Received By</label>
            <input
              type="text"
              className="form-control"
              id="receiverID"
              name="receiverID"
              value={currentUser.name}
              disabled
              required
            />
          </div>
        </div>
        {uploadedFiles.length <= 0 && (
          <div className="row">
            <div className="col-md-12">
              <label htmlFor="legalCompliance">
                <span className="text-danger">* </span>Legal
              </label>
              <div>
                <input
                  type="checkbox"
                  id="legalCompliance"
                  name="LegalCompliance"
                  onClick={() => {
                    validateForm();
                  }}
                  ref={legalComplianceRef}
                />
                <span>
                  {` I certify that the good or service being received is the good
                  or service purchased and that I have not and will not submit a
                  duplicate receipt for these purchases from any other source. I
                  understand that this Missing Receipt Affidavit should only be
                  used on rare occasions when a packing list is is not provided
                  or is otherwise unavailable.`}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Submit Button */}
      <div className="row">
        <div className="col-md-3 pull-left" onMouseEnter={notifyFormInvalid}>
          <button
            className="btn btn-link "
            onClick={e => {
              handleSubmit(e);
            }}
            disabled={!formIsValid}>
            Save Single Receipt and Leave Pack Slip Open
          </button>
        </div>
        <div className="col-md-4 pull-right" onMouseEnter={notifyFormInvalid}>
          <button
            type="button"
            onClick={handleSubmitAndCloseHead}
            className="btn btn-primary"
            disabled={!formIsValid}>
            Receive and Submit Pack Slip
          </button>
        </div>
      </div>
      {isSavingReceipt && <Spinner />}
      <style jsx="true">{`
        .form-section {
          border: 1px solid #d3d3d3;
          padding: 20px;
          margin-bottom: 20px;
          border-radius: 5px;
          background-color: #f9f9f9;
        }

        .item-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .btn-success {
          margin-top: 1em;
          background-color: #28a745 !important;
          border-color: #28a745 !important;
          color: #fff !important;
        }

        .btn-link {
          margin-top: 2em;
        }

        .btn-primary {
          margin-top: 2em;
          background-color: #007bff !important;
          border-color: #007bff !important;
          color: #fff !important;
        }

        .btn-danger {
          margin-top: 1em;
          background-color: #dc3545 !important;
          border-color: #dc3545 !important;
          color: #fff !important;
        }

        .btn-success:hover {
          background-color: #218838 !important;
          border-color: #1e7e34 !important;
        }

        .btn-primary:hover {
          background-color: #0056b3 !important;
          border-color: #004085 !important;
        }

        .btn-danger:hover {
          background-color: #c82333 !important;
          border-color: #bd2130 !important;
        }

        .remove-circle-btn {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 24px;
          color: #fff !important;
          background-color: #dc3545 !important;
          border: none;
          cursor: pointer;
        }

        .remove-circle-btn:hover {
          background-color: #c82333 !important;
        }

        .req-desc {
          resize: none !important;
        }

        .add-another-button {
          margin-top: 0;
          margin-bottom: 20px;
        }

        .uom-label {
          align-content: center;
          font-weight: bold;
          color: #007bff;
          margin-left: 10px;
        }
      `}</style>
    </div>
  );
}

export default ReceiptForm;

function toDateInputValue(dateObject) {
  const local = dateObject;
  local.setMinutes(dateObject.getMinutes() - dateObject.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
}
