import {useRef, useState, useEffect} from 'react';
import ReactSelect from '../../shared/components/inputs/ReactSelect';
import Spinner from '../../shared/components/Spinner';
import {useSelectedPO} from '../hooks';

export const ManualPOLookupForm = ({
  companyDropdownOptions,
  homeCompany,
  setActivePurchaseOrder,
  setManualLookupEnabled,
  configAlert
}) => {
  const [selectedCompany, setSelectedCompany] = useState(homeCompany);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const poRef = useRef(null);
  const hasPageBeenRendered = useRef(false);

  const {
    fetchPOByID,
    purchaseOrder,
    isLoading: loadingSelectedPO,
    error: selectedPOErr,
    setError: setSelectedPOErr
  } = useSelectedPO();

  useEffect(() => {
    if (!selectedPOErr) return;

    const alertDismiss = () => {
      setSubmitDisabled(false);
      setSelectedPOErr(null);
    };
    configAlert('error', selectedPOErr, alertDismiss);
  }, [selectedPOErr]);

  const handleCompanyChange = reactSelectEvent => {
    if (!reactSelectEvent) return setSelectedCompany(''); // Handles User deleting selection

    setSelectedCompany(reactSelectEvent.value);
  };

  const handlePONumChange = e => {
    const poNum = poRef.current.value;
    const poNumIsValid = poNum || poNum === 0;
    const formValid = selectedCompany && poNumIsValid;

    if (formValid) setSubmitDisabled(false);
    return;
  };

  const handleSubmit = () => {
    setSubmitDisabled(true);
    fetchPOByID(selectedCompany, parseInt(poRef.current.value));
  };

  useEffect(() => {
    // Prevent running on initial render
    if (hasPageBeenRendered.current) {
      if (purchaseOrder.notFound) {
        const alertMsg = `PO ${poRef.current.value} in Company ${selectedCompany} was not found in Epicor - Dismiss alert to try again`;
        const alertDismiss = () => {
          setSubmitDisabled(false);
        };

        configAlert('warning', alertMsg, alertDismiss);
        return;
      }
      setActivePurchaseOrder(purchaseOrder);
      setManualLookupEnabled(false);
    }
    if (!hasPageBeenRendered.current) hasPageBeenRendered.current = true;
  }, [purchaseOrder]);

  return (
    <>
      <div className="form-container">
        <div className="form-section">
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="companySelect">
                <span>
                  <span className="text-danger">* </span>Select Company:
                </span>
              </label>
              <ReactSelect
                id="companySelect"
                name="Company"
                value={selectedCompany}
                onChange={handleCompanyChange}
                options={companyDropdownOptions}
                placeholder="Select your Company"
                noResultsText="Company Not Found"
                required={true}
              />
            </div>

            <div className="col-md-6">
              <label htmlFor="poEntry">
                <span>
                  <span className="text-danger">* </span>PO Number:
                </span>
              </label>
              <input
                id="poEntry"
                name="Purchase Order Number"
                className="form-control"
                ref={poRef}
                onChange={handlePONumChange}
                placeholder={
                  selectedCompany ? 'Enter PO Number' : 'Select a Company First'
                }
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6" />
            <button
              className="btn-primary col-md-4 col-md-offset-4"
              onClick={handleSubmit}
              disabled={submitDisabled}>
              Find Purchase Order
            </button>
          </div>
        </div>
      </div>
      {loadingSelectedPO && <Spinner />}
    </>
  );
};

export default ManualPOLookupForm;
