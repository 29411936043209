import {useState} from 'react';
import {GET_WAREHOUSES} from '../queries';
import {callGqlServer} from '../gqlService';

let fetchFails = 1;

export const useWarehouses = () => {
  const [warehouses, setWarehouses] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const controller = new AbortController();

  const fetchWarehouses = async company => {
    try {
      if (!company || typeof company !== 'string')
        throw new Error('CompanyID is a required string for Warehouses lookup');
    } catch (e) {
      setError(e.message);
      return;
    }

    setIsLoading(true);

    const errMsg =
      fetchFails <= 3
        ? `Problem fetching warehouses on attempt ${fetchFails} -- see console and server logs for details`
        : `Something's really not right with fetching warehouses -- contact GTS or submit without Warehouse entry and let them know`;

    const fetchConfig = {
      signal: controller.signal,
      errMsg
    };

    const response = await callGqlServer(
      GET_WAREHOUSES,
      {company},
      fetchConfig
    );

    let gqlData = {};
    const {data, error} = response; // real server response with error status code
    if (data) gqlData = data;
    const {errors, data: successData} = gqlData; // GraphQL data or Errors

    if (error || errors) {
      fetchFails += 1;
      const err = error ? error : errors[0].message;
      console.error('Warehouses Error -> ', err);
      setError(errMsg);
    }
    if (successData?.warehouses) {
      fetchFails = 0;
      setWarehouses(successData.warehouses);
    }
    setIsLoading(false);
  };

  return {
    warehouses,
    setWarehouses,
    error,
    isLoading,
    setError,
    fetchWarehouses,
    cancelFetchWarehouses: controller.abort
  };
};
