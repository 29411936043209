import PropTypes from 'prop-types';
import React from 'react';

import HeaderApprovalButton from './HeaderApprovalButton';
import Logo from '../../auth/pages/bedrock_wordmark.png';
import SettingsContainer from './SettingsContainer';

const SearchBar = ({
  isUserManager,
  location,
  username,
  company,
  currentUser
}) => (
  <header className="search-bar">
    <img src={Logo} alt="TAIT Logo" />
    {isUserManager && <HeaderApprovalButton location={location} />}
    <SettingsContainer
      isUserManager={isUserManager}
      username={username}
      company={company}
      currentUser={currentUser}
    />
  </header>
);

SearchBar.propTypes = {
  username: PropTypes.string.isRequired,
  isUserManager: PropTypes.bool.isRequired,
  menuExpanded: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired
};

export default SearchBar;
