import Dialog from '../../shared/components/Dialog';

export const ReceiptDialog = props => {
  const {
    heading,
    title,
    onClose,
    activePO,
    receiptFormStart,
    lookupChoices, // Strictly for User Friendly Displays Names and Requestor
    alert,
    displayAlert,
    children
  } = props;

  return (
    <Dialog
      className="req-form modal-dialog"
      title={title}
      onConfirm={onClose}
      showFooter={false}
      onDismiss={onClose}>
      {displayAlert && alert}
      <div className="modal-content p-4">
        {heading && heading.length > 0 && (
          <p className="modal-title">{heading}</p>
        )}
        <div className="form-section">{children}</div>
      </div>
    </Dialog>
  );
};
