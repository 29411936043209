import {useEffect, useState, useRef, useCallback, memo} from 'react';
import styled from 'styled-components';

import {CheckCircle, ImageAdd} from '../icons';
import {File} from './File';
import {Queue, formatFileSize, formatFiles, getFileSizeMB} from '../utils';
// import Glyphicon from '../Glyphicon';

/* 
onFilesSelected: Function that will receive array of added files
className: if you want to add styles to the component
acceptedFileTypes: array of strings -- acceptable file types [".pdf", ".docx", ...etc]
useBase64 - File array with contain File objects (not blobs) with encodedData prop that will be base64 string

Exposes an array of file objects -- does not allow duplicate files to be uploaded
{
id: [fileName-lastModified-size]
displayName
size: [Formatted - number[Kb,Mb,Bytes]]
extension
blob: [originalFileObject]
useBase64 ? encodedData : [no property - undefined]
}
*/

let dragCount = 0; // Will help track enter/leave events on parent only
export const DragNDrop = memo(
  ({
    onFilesSelected,
    onErrorMessage,
    onWarningMessage,
    className,
    acceptedFileTypes,
    totalMaxSizeInMB,
    fileMaxSizeInMB,
    dropMsg,
    multiple,
    required,
    name,
    id,
    useBase64
  }) => {
    const [files, setFiles] = useState([]);
    const filesToProcess = useRef(new Queue());
    const fileCache = useRef({}); // Prevent user from uploading the same file more than once
    const [errorState, setErrorState] = useState(false);
    const [internalErrorMsg, setInternalErrorMsg] = useState('');
    const [internalWarningMsg, setInternalWarningMsg] = useState('');
    const [fileInDropZone, setFileInDropZone] = useState(false);
    const [totalFileSize, setTotalFileSize] = useState(0);
    const dragArea = useRef(null);
    const browseButtonRef = useRef(null);

    const acceptedFileString = acceptedFileTypes.join(' ');

    const handleRemoveFile = fileId => {
      let sizeToRemove = 0;
      const filteredFiles = files.filter(file => {
        const keepFile = file.id !== fileId;
        if (!keepFile) {
          sizeToRemove += file.blob.size;
          delete fileCache.current[fileId];
        }
        return keepFile;
      });

      setTotalFileSize(prevSize => prevSize - sizeToRemove);
      setFiles(filteredFiles);
    };

    // Exposes Files and Error Messages to Parent Components -- some inefficiency here -- look to memoize this component
    useEffect(() => {
      if (onFilesSelected) onFilesSelected(files);
    }, [files]);

    useEffect(() => {
      if (onErrorMessage) onErrorMessage(internalErrorMsg);
      if (!onErrorMessage) return console.error(internalErrorMsg);
    }, [internalErrorMsg]);

    useEffect(() => {
      if (onWarningMessage) onWarningMessage(internalWarningMsg);
    }, [internalWarningMsg]);

    // ----------------------ERROR HANDLING--------------------------------------
    useEffect(() => {
      const totalSizeInMB = getFileSizeMB(totalFileSize);
      const totalSizeValid = totalSizeInMB < totalMaxSizeInMB;

      if (totalMaxSizeInMB && !totalSizeValid) {
        setErrorState(true);
        setInternalErrorMsg(
          `Total Upload of ${totalSizeInMB}MB exceeds limit of ${totalMaxSizeInMB}MB`
        );
      }
      if (totalSizeValid) setErrorState(false);
    }, [totalFileSize]);

    const validateFile = useCallback(file => {
      const errors = {};
      let typeIsValid = true; // If you don't set this we'll always get and error object on the result
      let sizeIsValid = true;
      if (acceptedFileTypes) {
        typeIsValid = acceptedFileString.includes(file.extension);
        if (!typeIsValid)
          errors.typeErr = `${file.displayName} is an unsupported file type -//- `;
      }
      if (fileMaxSizeInMB) {
        const fileSizeInMB = getFileSizeMB(file.blob.size);
        sizeIsValid = fileSizeInMB < fileMaxSizeInMB;

        if (!sizeIsValid)
          errors.sizeErr = `${file.displayName} - ${fileSizeInMB}MB is over the file size limit of ${fileMaxSizeInMB}MB -//- `;
      }

      if (!typeIsValid || !sizeIsValid) return {errors, isValid: false};

      return {isValid: true};
    }, []);
    // ----------------------END ERROR HANDLING-----------------------------------

    // ----------------------DRAG/CLICK EVENT HANDLERS----------------------------
    const blockEventDefault = e => {
      e.stopPropagation();
      if (e.target.id === 'browse') return; // allows file browse even through
      e.preventDefault();
    };

    const handleFileEnter = e => {
      blockEventDefault(e);
      dragCount++;
      setFileInDropZone(true);
    };
    const handleFileLeave = e => {
      blockEventDefault(e);
      dragCount--;
      if (dragCount > 0) return; // Stops ending drag state when dragging over children
      setFileInDropZone(false);
    };

    const handleBrowseClick = e => {
      // Very weird, but without this handler, input element event never bubbles
      e.stopPropagation(); // Stops Label/SVG propagation, but allows Input click to bubble
    };

    // Probably overkill here - but will assign these to dragArea ref - aka area that can receive files
    useEffect(() => {
      if (!dragArea) return;
      const el = dragArea.current;
      el.addEventListener('dragenter', handleFileEnter);
      el.addEventListener('dragleave', handleFileLeave);
      el.addEventListener('drop', handleDrop);
      el.addEventListener('dragover', blockEventDefault);
      el.addEventListener('dragstart', blockEventDefault);
      el.addEventListener('dragend', blockEventDefault);
      el.addEventListener('drag', blockEventDefault);
      return () => {
        el.removeEventListener('dragenter', handleFileLeave);
        el.removeEventListener('dragleave', handleFileLeave);
        el.removeEventListener('drop', handleDrop);
        el.removeEventListener('dragover', blockEventDefault);
        el.removeEventListener('dragstart', blockEventDefault);
        el.removeEventListener('dragend', blockEventDefault);
        el.removeEventListener('drag', blockEventDefault);
      };
    }, [dragArea]);
    // ----------------------END EVENT HANDLERS----------------------------

    // ----------------------PROCESS FILES HANDLERS------------------------
    // Memoize this function definition
    const processFiles = useCallback(
      filesArr => {
        let totalSizeToAdd = 0;
        let warningMsg = '';
        const formattedFiles = formatFiles(Array.from(filesArr));
        // Prevents Dupes by caching files by ID
        const filesSansDupes = formattedFiles?.reduce((filteredFiles, file) => {
          if (fileCache.current[file.id]) return filteredFiles;

          const result = validateFile(file); // Won't add files that don't pass muster
          if (result.errors) {
            const {typeErr, sizeErr} = result.errors;
            if (typeErr) warningMsg = warningMsg += `${typeErr} \n`;
            if (sizeErr) warningMsg = warningMsg += `${sizeErr} \n`;
            return filteredFiles;
          }

          fileCache.current[file.id] = file;
          filteredFiles.push(file);
          totalSizeToAdd += file.blob.size;
          return filteredFiles;
        }, []);

        if (useBase64)
          filesSansDupes?.forEach(file => {
            file.fileReader = new FileReader();
            file.isProcessing = false;
            file.updateProcessing = processingBool => {
              if (typeof processingBool !== 'boolean')
                throw new Error('File Processing flag must be a boolean');
              file.isProcessing = processingBool;
            }; // Helps not break React rules even though we'd probably be ok here
          });

        return {filesSansDupes, totalSizeToAdd, warningMsg};
      },
      [fileCache.current, filesToProcess]
    );

    // WHEN FILES ARE SELECTED FROM BROWSE BUTTON
    const handleFileChange = e => {
      const selectedFiles = e.target.files;
      if (selectedFiles && selectedFiles.length > 0) {
        const {totalSizeToAdd, filesSansDupes, warningMsg} = processFiles(
          Array.from(selectedFiles)
        );

        if (warningMsg) setInternalWarningMsg(warningMsg);
        setTotalFileSize(prevSize => (prevSize += totalSizeToAdd));
        setFiles(prevFiles => [...prevFiles, ...filesSansDupes]);
        browseButtonRef.current.value = ''; // Allows us to choose the same file again if removed from component
      }
    };

    // WHEN FILES ARE DROPPED
    const handleDrop = event => {
      event.preventDefault();
      const droppedFiles = event.dataTransfer.files;

      // 'Validate Files to ensure they match type and size'
      if (droppedFiles && droppedFiles.length > 0) {
        const {totalSizeToAdd, filesSansDupes, warningMsg} = processFiles(
          Array.from(droppedFiles)
        );

        if (warningMsg) setInternalWarningMsg(warningMsg);
        setTotalFileSize(prevSize => (prevSize += totalSizeToAdd));
        setFiles(prevFiles => [...prevFiles, ...filesSansDupes]);
      }
      setFileInDropZone(false);
      dragCount = 0; // Reset for proper drag states on next item
    };
    // ----------------------END PROCESS FILES HANDLERS------------------------

    const filesList = files.map(file => (
      <File
        file={file}
        handleRemoveFile={handleRemoveFile}
        key={file.id}
        onError={setInternalErrorMsg}
      />
    ));

    const draggingFileClass = fileInDropZone ? 'dragging-file' : '';
    const holdingFilesClass =
      files.length > 0 ? 'upload-box active' : 'upload-box';
    const errorStateClass = errorState ? 'error-state' : '';
    const totalSizeFooter = totalMaxSizeInMB
      ? `/${totalMaxSizeInMB}MB total)`
      : 'total)';

    return (
      <DragDrop className={className} onClick={blockEventDefault}>
        <DocumentUploader
          className={`document-uploader ${holdingFilesClass} ${draggingFileClass} ${errorStateClass}`}
          innerRef={dragArea}>
          <div className="upload-info">
            <HeadingContainer>
              <p>{`${dropMsg}`}</p>
              <p>{`Supports: ${acceptedFileString}`}</p>
            </HeadingContainer>
          </div>

          <div>
            <HiddenFileBrowse
              type="file"
              hidden
              id="browse"
              innerRef={browseButtonRef}
              onChange={handleFileChange}
              accept={acceptedFileString}
              multiple={multiple}
              required={required}
            />
            <label
              htmlFor="browse"
              className="browse-btn"
              onClick={handleBrowseClick}>
              <ImageAdd />
            </label>
          </div>

          {files.length > 0 && (
            <div className="file-list">
              <div className="file-list__container">{filesList}</div>
            </div>
          )}

          {files.length > 0 && (
            <div className="success-file">
              <CheckCircle />
              <p>{files.length} file(s) selected - </p>
              <p>- {`(${formatFileSize(totalFileSize)} ${totalSizeFooter}`}</p>
            </div>
          )}
        </DocumentUploader>
      </DragDrop>
    );
  }
);

const DragDrop = styled.section`
  background: #fff;
  border: 1px solid var(--border-color);
  border-radius: 8px;
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DocumentUploader = styled.div`
  border: 2px solid lightgray;
  background-color: #f0f0f0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;

  &.active {
    border-color: #6dc24b;
  }

  &.dragging-file {
    border: 2px dashed lightgray;
    background-color: lightyellow;
  }

  &.error-state {
    border: 2px solid red;
    background-color: lightpink;
  }

  .upload-info {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    svg {
      font-size: 36px;
      margin-right: 1rem;
    }

    div {
      p {
        margin: 0;
        font-size: 16px;
      }

      p:first-child {
        font-weight: bold;
      }
    }
  }

  .file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border: 1px solid var(--border-color);
    border-radius: 8px;

    .file-info {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      flex: 1;

      p {
        margin: 0;
        font-size: 14px;
        color: #333;
      }
    }
  }

  .file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border: 1px solid var(--border-color);
    border-radius: 8px;
  }

  .browse-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    cursor: pointer;
    background-color: var(--primary-color);
    &:hover {
      background-color: transparent;
    }
  }

  .file-actions {
    cursor: pointer;
    padding-left: 0.5rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .success-file {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    justify-content: center;
    align-items: center;
    color: #3cb878;

    .circle-check {
      height: 8%;
      width: 8%;
    }

    p {
      margin: 0;
      font-size: 14px;
      font-weight: bold;
    }
  }

  input[type='file'] {
    display: none;
  }
`;
// For some reason this is not hiding the browse input on its own
const HiddenFileBrowse = styled.input`
  display: none;
`;
