import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {CSSTransition} from 'react-transition-group';

/**
 * <Alert />
 * Wrapper for Bootstrap alert box
 */
const Alert = forwardRef(({error, warning, success, dismiss}, ref) => {
  let className, message;

  if (error) {
    className = 'alert-danger';
    message = error;
  } else if (warning) {
    className = 'alert-warning';
    message = warning;
  } else if (success) {
    className = 'alert-success';
    message = success;
  }
  var closeButton = null;
  if (dismiss) {
    closeButton = (
      <button
        type="button"
        className="close"
        onClick={dismiss}
        aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    );
  }
  return (
    <div ref={ref}>
      <CSSTransition
        in={!!message}
        timeout={1000}
        unmountOnExit
        classNames="fadein">
        <div className={className + ' alert'}>
          {closeButton}
          {message}
        </div>
      </CSSTransition>
    </div>
  );
});
Alert.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  success: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dismiss: PropTypes.func
};
export default Alert;
