import {useState} from 'react';
import {pick} from 'lodash';

import {SAVE_RECEIPT} from '../queries';
import {callGqlServer} from '../gqlService';

let postFails = 1;
export const useCreateReceipt = () => {
  const [receipt, setReceipt] = useState(null);
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const controller = new AbortController();

  const postReceipt = async receipt => {
    try {
      if (!receipt || typeof receipt !== 'object')
        throw new Error(
          'Receipt is a required object to save a receipt to Epicor'
        );
    } catch (e) {
      setError(e.message);
      return;
    }
    const whiteListProperties = [
      'company',
      'PONum',
      'line',
      'release',
      'packingSlip',
      'vendorNum',
      'purPointID',
      'warehouseID',
      'binID',
      'receiverID',
      'qtyReceived',
      'dateReceived',
      'attachments',
      'comment',
      'addHead',
      'addDetailAttachment'
    ];
    const receiptToSave = pick(receipt, whiteListProperties);

    setIsSaving(true);

    const errMsg =
      postFails <= 3
        ? `Problem saving receipt on attempt ${postFails} -- see console and server logs for details`
        : `Something's really not right with saving receipts -- contact GTS`;

    const fetchConfig = {
      signal: controller.signal,
      errMsg
    };

    const response = await callGqlServer(
      SAVE_RECEIPT,
      {receipt: receiptToSave},
      fetchConfig
    );

    let gqlData = {};
    const {data, error} = response; // real server response with error status code
    if (data) gqlData = data;
    const {errors, data: successData} = gqlData; // GraphQL data or Errors

    if (error || errors) {
      postFails += 1;
      const err = error ? error : errors[0].message;
      console.error('Create Receipt Error -> ', err);
      setError(errMsg);
    }

    if (successData?.createReceipt) {
      postFails = 0;
      setReceipt(successData.createReceipt);
    }
    setIsSaving(false);
  };

  return {
    receipt,
    setReceipt,
    error,
    isSaving,
    setError,
    postReceipt,
    cancelpostReceipt: controller.abort
  };
};

/*
On any success we'll get a return object like this from the server -> 
{
          receiptHeadSuccess: string ||
          receiptDetailSuccess: string ||
          receiptDetailFail: string,
          attachToDetailSuccess: [string] ||
          attachTpDetailFail: [string],
    } 

If creating a receipt head fails -- the whole things fails
If creating a head was not necessary (selected existing), or it succeeded
THEN a detail could succeed or fail. 
If a detail fails -- we don't move on to attachments
If a detail succeeds -- we'll try attachments which could be an array of files
So each uploaded file could succeed or fail
*/
