import Dialog from '../../shared/components/Dialog';

const PODialog = ({
  heading,
  title,
  onClose,
  alert,
  displayAlert,
  lookupEnabled,
  manualLookupEnabled,
  setLookupEnabled,
  setManualLookupEnabled,
  companyOptionsLoaded,
  poSelected,
  children
}) => {
  // const showAlert = (hdCaseNum) => {
  //   alert(`NCR created successfully. HD Case Number: ${hdCaseNum}`);
  // }

  // const showErrorAlert = (error) => {
  //   alert(error);
  // }

  const handleLookupSwitch = () => {
    setLookupEnabled(!lookupEnabled);
    setManualLookupEnabled(!manualLookupEnabled);
  };

  return (
    <>
      <Dialog
        className="po-dialog"
        title={title}
        onConfirm={onClose}
        showFooter={false}
        onDismiss={onClose}>
        {displayAlert && alert}
        <div className="po-dialog-content row">
          <div className="col-md-6">
            {heading && heading.length > 0 && (
              <p className="po-dialog-heading">{heading}</p>
            )}
          </div>
          <div className="col-md-3 pull-right">
            {!poSelected && companyOptionsLoaded && (
              <button
                type="button"
                className="btn-link pull-right"
                onClick={handleLookupSwitch}>
                {lookupEnabled ? 'Manual Lookup' : 'Back to Project Lookup'}
              </button>
            )}
          </div>
        </div>
        {children}
      </Dialog>
      <style jsx="true">{`
        .po-dialog {
          max-height: 85vh;
        }
      `}</style>
    </>
  );
};

export default PODialog;
