import POLine from '../components/POLine';

// Helpers -- Gets releases, and receipt lines associated with POLine
const filterForLine = (dataArr, lineNum) =>
  dataArr.filter(detail => {
    if (!detail) return;
    return parseInt(detail.line) === lineNum;
  });

export const POLineContainer = ({
  lines,
  releaseData: {releases, associatedProjects, associatedRequestors},
  recHeadCache,
  receiptDetails,
  setDisplayReceiptForm,
  setReceiptFormStart
}) => {
  // If multiple proj/requestors we'll display them on the release rather than the PO
  const extraColFlags = {
    poHasMultipleProjs: associatedProjects.length > 1,
    poHasMultipleReqs: associatedRequestors.length > 1
  };

  const POLines = lines.map((line, idx) => {
    const parsedLineNum = parseInt(line.POLine);
    return (
      <POLine
        setDisplayReceiptForm={setDisplayReceiptForm}
        setReceiptFormStart={setReceiptFormStart}
        extraColFlags={extraColFlags}
        releases={filterForLine(releases, parsedLineNum)}
        recHeadCache={recHeadCache}
        receiptDetails={filterForLine(receiptDetails, parsedLineNum)}
        key={`${line}-${idx}`}
        {...line}
      />
    );
  });

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th scope="col" />
            <th scope="col" className="headerCell">
              Line
            </th>
            <th scope="col" className="headerCell">
              Part Number
            </th>
            <th scope="col" className="headerCell">
              Description
            </th>
            <th scope="col" className="headerCell">
              Total Qty Expected
            </th>
          </tr>
        </thead>
        <tbody>{POLines}</tbody>
      </table>
    </div>
  );
};
