import {useState} from 'react';

import {CLOSE_PACK_SLIP} from '../queries';
import {callGqlServer} from '../gqlService';

let postFails = 1;
export const useClosePackSlip = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const controller = new AbortController();

  const closePackSlip = async (
    company,
    packSlipID,
    vendorNum,
    purPointID,
    receivePersonID
  ) => {
    try {
      if (!company || typeof company !== 'string')
        throw new Error(
          'CompanyID is a required string to close out a packing slip'
        );
      if (!packSlipID || typeof packSlipID !== 'string')
        throw new Error(
          'Packing Slip ID is a required string to close out a packing slip'
        );
      if (!vendorNum || typeof vendorNum !== 'number')
        throw new Error(
          'Vendor ID is a required number to close out a packing slip'
        );
      if (purPointID && typeof purPointID !== 'string')
        // This can be an empty string in epicor
        throw new Error(
          'PurPoint is a required string to close out a packing slip'
        );
    } catch (e) {
      setError({packingSlipNum: packSlipID, error: e.message});
      return;
    }

    setIsUpdating(true);

    const errMsg =
      postFails <= 3
        ? `Problem closing packing slip ${(packSlipID,
          vendorNum,
          purPointID)} on attempt ${postFails} -- see console and server logs for details`
        : `Something's really not right with closing out packing slip ${(packSlipID,
          vendorNum,
          purPointID)} -- contact GTS`;

    const fetchConfig = {
      signal: controller.signal,
      errMsg
    };

    const response = await callGqlServer(
      CLOSE_PACK_SLIP,
      {
        company,
        packSlipNum: packSlipID,
        vendorNum,
        receivePersonID,
        purPointID
      },
      fetchConfig
    );

    let gqlData = {};
    const {data, error} = response; // real server response with error status code
    if (data) gqlData = data;
    const {errors, data: successData} = gqlData; // GraphQL data or Errors

    if (error || errors) {
      postFails += 1;
      const err = error ? error : errors[0].message;
      console.error('Error Closing Out Pack Slip -> ', err);
      setError(errMsg);
    }

    if (successData?.closePackSlip) {
      postFails = 0;
      setResponse(successData.closePackSlip);
    }
    setIsUpdating(false);
  };

  return {
    response,
    setResponse,
    error,
    setError,
    isUpdating,
    closePackSlip,
    cancelClosePackSlip: controller.abort
  };
};
