import React from 'react';
import PropTypes from 'prop-types';
import {Route, Switch} from 'react-router-dom';

import SearchBar from '../components/SearchBar';
import appPage from '../hoc/appPage';
import NoMatch from './NoMatch';
import TimesheetPage from '../../timesheet/pages/TimesheetPage';
import ApprovalPage from '../../approval/pages/ApprovalPage';
import FatalErrorDialog from '../../shared/components/FatalErrorDialog';

const MainPage = ({
  currentUser,
  fatalError,
  location,
  menuExpanded,
  rootClick,
  toggleMenu
}) => (
  <div className="main-page" onClick={rootClick}>
    <SearchBar
      isUserManager={currentUser.ApprovalsView}
      company={currentUser.Company}
      location={location}
      menuExpanded={menuExpanded}
      toggleMenu={toggleMenu}
      username={currentUser.Name}
      currentUser={currentUser}
    />
    {fatalError && <FatalErrorDialog message={fatalError} />}
    <main className="content">
      <Switch>
        <Route path="/approval/:user/:date/:view" component={ApprovalPage} />
        <Route path="/approval" component={ApprovalPage} />
        <Route path="/timesheet/:date" component={TimesheetPage} />
        <Route path="/timesheet" component={TimesheetPage} />
        <Route component={NoMatch} />
      </Switch>
    </main>
  </div>
);

MainPage.propTypes = {
  currentUser: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  rootClick: PropTypes.func.isRequired,
  fatalError: PropTypes.string.isRequired
};

export default appPage(MainPage);
