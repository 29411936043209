import {useState} from 'react';
import {GET_PO_BY_ID} from '../queries';
import {callGqlServer} from '../gqlService';

/* This hook fetches a PO based on company and PO number
If not found, will return {notFound: true}
IF error -- sets Error state
Otherwise returns PO object */

const poShape = {
  lines: [],
  releaseData: {},
  receiptHeads: [],
  receiptDetails: []
};

let fetchFails = 1;
export const useSelectedPO = () => {
  const [purchaseOrder, setPurchaseOrder] = useState(poShape);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const controller = new AbortController();

  const fetchPOByID = async (company, PONum) => {
    try {
      if (!company || typeof company !== 'string')
        throw new Error('CompanyID is a required string for PO lookup');
      if (!PONum || typeof PONum !== 'number')
        throw new Error('PONum is a required number for PO lookup');
    } catch (e) {
      setError(e.message);
      return;
    }

    setIsLoading(true);

    const errMsg =
      fetchFails <= 3
        ? `Problem fetching selected PO on attempt ${fetchFails} -- see console and server logs for details`
        : `Something's really not right with fetching your selected PO -- contact GTS`;

    const fetchConfig = {
      signal: controller.signal,
      errMsg
    };

    const response = await callGqlServer(
      GET_PO_BY_ID,
      {company, PONum},
      fetchConfig
    );

    let gqlData = {};
    const {data, error} = response; // real server response with error status code
    if (data) gqlData = data;
    const {errors, data: poData} = gqlData; // GraphQL data or Errors
    let poById = poData?.poById;

    if (error || errors) {
      fetchFails += 1;
      const err = error ? error : errors[0].message;
      console.error('Selected PO Error -> ', err);
      setError(errMsg);
    }

    if (!poById) {
      poById = {notFound: true};
    }
    fetchFails = 0;
    setPurchaseOrder(poById);
    setIsLoading(false);
  };

  return {
    purchaseOrder,
    setPurchaseOrder,
    error,
    isLoading,
    setError,
    fetchPOByID,
    cancelGetPO: controller.abort
  };
};
