import {Receipt} from '../components/Receipt';

export const ReceiptContainer = ({
  receiptDetails,
  recHeadCache,
  poHasMultipleProjs,
  poHasMultipleReqs
}) => {
  const receipts = receiptDetails.map((receipt, idx) => {
    const {packingSlipNum, packLine, quantity, wareHouseCode, binNum} = receipt;
    const associatedHead = recHeadCache[packingSlipNum];
    return (
      <Receipt
        packingSlipNum={packingSlipNum}
        packLine={packLine}
        quantity={quantity}
        dateEntered={associatedHead.dateEntered}
        wareHouseCode={wareHouseCode}
        binNum={binNum}
        enteredBy={associatedHead.enteredBy}
        key={`${receipt}-${idx}`}
      />
    );
  });

  let colSpan = 7;
  if (poHasMultipleProjs) colSpan += 1;
  if (poHasMultipleReqs) colSpan += 1;

  return (
    <td colSpan={colSpan}>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th scope="col" className="headerCell">
              Pack Slip / Pack Line
            </th>
            <th scope="col" className="headerCell">
              Quantity
            </th>
            <th scope="col" className="headerCell">
              Date Entered
            </th>
            <th scope="col" className="headerCell">
              Warehouse
            </th>
            <th scope="col" className="headerCell">
              Bin
            </th>
            <th scope="col" className="headerCell">
              Entered By
            </th>
          </tr>
        </thead>
        <tbody>{receipts}</tbody>
      </table>
    </td>
  );
};
