import {useState, useEffect, useCallback, memo} from 'react';
import Glyphicon from '../../Glyphicon';
import {ProgressBar} from './ProgressBar';

/*
file: Custom File object created in DragNDrop {displayName, extension, fileReader?, isProcessing?, updateProcessing?}
handleRemoveFile: Function that will remove file from list
*/

// Add Property on the File -- isProcessing - check that when I go to upload, if in progress we wait, if not we can send to server

export const File = memo(({file, handleRemoveFile, onError}) => {
  const {id, displayName, fileReader} = file;

  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const displayProgress = progress > 0 && progress < 100;

  useEffect(() => {
    if (fileReader.readyState === 1) return; // Checks loading status -- avoids problems in strict mode
    if (fileReader) {
      fileReader.addEventListener('loadstart', handleLoadStart);
      fileReader.addEventListener('loadend', handleLoadEnd);
      fileReader.addEventListener('progress', handleProgress);
      fileReader.addEventListener('error', handleError);
      fileReader.addEventListener('abort', handleAbort);
      fileReader.readAsDataURL(file.blob);
    }

    return () => {
      if (fileReader && isProcessing) fileReader.abort();
    };
  }, []);

  const handleLoadStart = useCallback(e => {
    setIsProcessing(true);
    file.updateProcessing(true); // We'll check this when sending file to server -- make sure it's done
  }, []);

  const handleLoadEnd = useCallback(e => {
    file.updateProcessing(false);
    file.encodedData = fileReader.result.split(',')[1];
    setProgress(100);
    setIsProcessing(false);
  }, []);
  const handleProgress = useCallback(e => {
    const {loaded, total} = e;
    const currentProgress = Math.ceil((loaded / total).toFixed(2) * 100);
    setProgress(currentProgress);
  }, []);

  const handleError = useCallback(e => {
    onError(fileReader.error);
  }, []);

  const handleAbort = useCallback(e => {
    console.log('File Reader Aborted -> ', e);
  }, []);

  return (
    <>
      <div className="file-item" key={file.id}>
        <div className="file-info">
          <p>{`${file.displayName}`}</p>
        </div>
        <span
          className="file-actions"
          onClick={() => handleRemoveFile(file.id)}>
          <span>{displayProgress && <ProgressBar progress={progress} />}</span>
          <Glyphicon glyph="remove" />
        </span>
      </div>
    </>
  );
});
