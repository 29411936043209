import {pick} from 'lodash';

/*
By default this will send a POST http request to /api/graphql with content headers application/json and the passed in query and variables
All properties can be overwritten and further customized using the config object argument
--- This can be used in other places -- didn't want to refactor the whole app on a whim -- consider expanding usage and moving to utils

config?: {
  headers?: {},
  method?: string
  endpoint?: string
  fetchOptions?: {} // remaining fetch options
  errMsg: string

}
*/

export const callGqlServer = async (query, gqlVariables, config) => {
  const {
    headers: configHeaders,
    method: configMethod,
    endpoint: configEndpoint,
    fetchOptions,
    errMsg: configMsg
  } = config;

  if (!query || typeof query !== 'object')
    throw new Error(
      'GQL Query or Mutation name is required and must be a graphQL query object created by GQL Library!'
    );
  if (
    gqlVariables &&
    (typeof gqlVariables !== 'object' || Array.isArray(gqlVariables))
  )
    throw new Error('GQL Variables must be a javascript object');
  if (config && (typeof config !== 'object' || Array.isArray(config)))
    throw new Error('Config for GQL call must be a javascript object');
  if (
    configHeaders &&
    (typeof configHeaders !== 'object' || Array.isArray(configHeaders))
  )
    throw new Error('Headers for GQL call must be a javascript object');
  if (
    fetchOptions &&
    (typeof fetchOptions !== 'object' || Array.isArray(fetchOptions))
  )
    throw new Error('Fetch Options for GQL call must be a javascript object');
  // Consider adding whitelist for acceptable methods

  const headersWhitelist = ['Content-Type']; // Add to this as needed if using more headers or custom headers
  const fetchOptionsWhitelist = ['signal', 'mode', 'priority']; // Add to this as needed if using more fetch options than listed -- https://developer.mozilla.org/en-US/docs/Web/API/RequestInit
  const gqlEndpoint = '/api/graphql';

  const userHeaders = configHeaders
    ? pick(configHeaders, headersWhitelist)
    : {};
  const headers = {
    'Content-Type': 'application/json',
    ...userHeaders
  };

  const method = configMethod ? configMethod : 'POST'; //gql should ALWAYS be post -- but hey why not some flexibility
  const endpoint = configEndpoint ? configEndpoint : gqlEndpoint;
  const errMsg = configMsg
    ? configMsg
    : `Problem with a fetch request - tell GTS to craft a more specific message`;
  const otherOptions = fetchOptions
    ? pick(fetchOptions, fetchOptionsWhitelist)
    : {};

  const variables = gqlVariables ? gqlVariables : {};
  const reqBody = JSON.stringify({
    query,
    variables
  });

  const returnObj = {
    data: null,
    error: null
  };

  try {
    const response = await fetch(endpoint, {
      method,
      headers,
      body: reqBody,
      ...otherOptions
    });
    if (!response.ok) {
      throw new Error(errMsg);
    }
    returnObj.data = await response.json();
    return returnObj;
  } catch (error) {
    // We can get an actual error status from the server (error) or a graphQL error ({data, errors}) -- this will account for real errors
    console.error(errMsg, error);
    returnObj.error = errMsg;
    return returnObj;
  }
};
