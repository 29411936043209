export function formatFileSize(number) {
  if (number < 1e3) {
    return `${number} bytes`;
  } else if (number >= 1e3 && number < 1e6) {
    return `${(number / 1e3).toFixed(1)} KB`;
  } else {
    return `${(number / 1e6).toFixed(1)} MB`;
  }
}

export const getFileSizeMB = size => {
  return (size / 1e6).toFixed(2);
};

export const formatFiles = fileArr => {
  if (!Array.isArray(fileArr))
    throw new Error(
      'File ARR is not an arr - Formatting Files requires an array'
    );

  if (fileArr.length <= 0) return;
  return fileArr.map(file => {
    const {lastModified, name, size} = file;

    const lastPeriod = name.lastIndexOf('.');
    const extension = name.slice(lastPeriod);
    const nameSansExt = name.slice(0, lastPeriod);
    const formattedSize = formatFileSize(size);
    const displayName =
      nameSansExt.length < 13
        ? `${nameSansExt}${extension} (${formattedSize})`
        : `${name.slice(0, 14)}... ${extension} (${formattedSize})`;
    const formattedFile = {
      id: `${name}-${lastModified}-${size}`,
      displayName,
      extension,
      formattedSize: formattedSize,
      blob: file
    };
    return formattedFile;
  });
};

// Not used in current version -- but don't want to delete
export class Queue {
  #data = {};
  #tailIndex = 0;
  #frontIndex = 0;
  #length = 0;

  enqueue(value) {
    this.#data[this.#tailIndex] = value;
    this.#tailIndex += 1;
    this.#length = this.#tailIndex - this.#frontIndex;
  }

  dequeue() {
    if (this.#length <= 0) return;
    const valToShift = this.#data[this.#frontIndex];
    delete this.#data[this.#frontIndex];
    this.#length = this.#tailIndex - this.#frontIndex;
    if (this.#length <= 0) {
      this.#frontIndex = 0;
      this.#tailIndex = 0;
    }
    return valToShift;
  }

  peek() {
    return this.#data[this.#frontIndex];
  }

  print() {
    JSON.stringify(this.#data);
  }
}
