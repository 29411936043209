import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import {compose} from 'recompose';
import withSignout from '../../auth/hoc/withSignout';
import styled from 'styled-components';
import getGreetingTime from '../utils/getGreetingTime.js';
import Tooltip from 'rc-tooltip';
import ApprovalButton from '../components/ApprovalButton';
import NCRDialog from '../../shared/components/ncr-dialog';
import {
  NCRCOMPANYLIST_LOOKUP_QUERY,
  REQ_ALLOW_REQ_LOOKUP_QUERY
} from '../../timesheet/queries.js';
import RequisitionDialog from '../../shared/components/requisition-dialog';
import ViewOpenReqsModal from '../../shared/components/requisition-dialog/ViewRequisition.js';
import {timesheetFields} from '../../shared/utils/queries.js';
import {POContainer} from '../../purchaseOrder/containers/POContainer.jsx';

const isMobile = window.innerWidth < 1024;

const SettingsContainer = ({
  isUserManager,
  username,
  company,
  location,
  currentUser
}) => {
  const {permissionsGroups} = currentUser;
  const [menuOpen, setMenuOpen] = useState(false);
  const [isNcrDialogOpen, setIsNcrDialogOpen] = useState(false);
  const [isRequisitionDialogOpen, setIsRequisitionDialogOpen] = useState(false);
  const [isViewOpenReqsModalOpen, setIsViewOpenReqsModalOpen] = useState(false);
  const [isUserReqAllowed, setIsUserReqAllowed] = useState(false);
  const [isPODialogueOpen, setIsPODialogueOpen] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [isPOReceiptAllowed, setIsPOReceiptAllowed] = useState(
    permissionsGroups != null && permissionsGroups.includes('REC')
  );
  const menuRef = useRef(null);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const openNcrDialog = () => setIsNcrDialogOpen(true);
  const closeNcrDialog = () => setIsNcrDialogOpen(false);
  const openViewOpenReqsModal = () => setIsViewOpenReqsModalOpen(true);
  const closeViewOpenReqsModal = () => setIsViewOpenReqsModalOpen(false);

  const togglePODialogue = () => {
    setIsPODialogueOpen(!isPODialogueOpen);
  };

  const openRequisitionDialog = () => {
    setIsRequisitionDialogOpen(true);
  };

  const closeRequisitionDialog = () => {
    setIsRequisitionDialogOpen(false);
  };

  const handleClickOutside = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  const fetchRequisitionPermissions = async () => {
    try {
      const response = await fetch('/api/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: REQ_ALLOW_REQ_LOOKUP_QUERY,
          variables: {company}
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch requisition permissions');
      }

      const data = await response.json();

      if (data.data.allowReq) {
        const userAllowed = data.data.allowReq.some(user => {
          return user.UserFile_DcdUserID === currentUser.epicorUserID;
        });

        setIsUserReqAllowed(userAllowed);
      }
    } catch (error) {
      console.error('Error fetching requisition permissions:', error);
    }
  };

  useEffect(() => {
    if (menuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  });

  useEffect(() => {
    if (menuOpen) document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [menuOpen]);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await fetch('/api/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            query: NCRCOMPANYLIST_LOOKUP_QUERY,
            variables: {}
          })
        });

        if (!response.ok) {
          throw new Error('Companies fetch failed');
        }

        const data = await response.json();
        setCompanies(data.data.ncrCompanyList);
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };

    fetchCompanies();
  }, [company]);

  useEffect(() => {
    if (company) {
      fetchRequisitionPermissions();
    }
  }, [username, company]);

  return (
    <div className="pull-right">
      {!isMobile && (
        <Container className="global-nav">
          <h4>
            Good {getGreetingTime()}, {username}!
          </h4>
          <MenuIcon onClick={toggleMenu}>
            <i
              className={`fa ${menuOpen ? 'fa-times' : 'fa-bars'}`}
              aria-hidden="true"></i>
          </MenuIcon>
          <Menu open={menuOpen}>
            <MenuHeader className="menu-header">
              <Tooltip
                placement="left"
                overlay={<span>Quick Start Guide</span>}>
                {isUserManager ? (
                  company === '100' ? (
                    <a href="/US-Supervisor.pdf" target="_blank">
                      Quick Start Guide
                    </a>
                  ) : (
                    <a href="/UK-Supervisor.pdf" target="_blank">
                      Quick Start Guide
                    </a>
                  )
                ) : company === '100' ? (
                  <a href="/US-Non-Supervisor.pdf" target="_blank">
                    Quick Start Guide
                  </a>
                ) : (
                  <a href="/UK-Non-Supervisor.pdf" target="_blank">
                    Quick Start Guide
                  </a>
                )}
              </Tooltip>
              <Tooltip placement="left" overlay={<span>NCR Form</span>}>
                <button onClick={openNcrDialog} className="ncr-menu-item">
                  NCR Form
                </button>
              </Tooltip>

              {isUserReqAllowed && (
                <>
                  <Tooltip
                    placement="left"
                    overlay={<span>Requisition Form</span>}>
                    <button
                      onClick={() => setIsRequisitionDialogOpen(true)}
                      className="ncr-menu-item">
                      Requisition Form
                    </button>
                  </Tooltip>
                  <Tooltip
                    placement="left"
                    overlay={<span>View Open Reqs</span>}>
                    <button
                      onClick={openViewOpenReqsModal}
                      className="ncr-menu-item">
                      View Open Reqs
                    </button>
                  </Tooltip>
                </>
              )}
              {isPOReceiptAllowed && (
                <Tooltip
                  placement="left"
                  overlay={<span>Receive for Active Purchase Orders</span>}>
                  <button onClick={togglePODialogue} className="ncr-menu-item">
                    Purchase Order Receipts
                  </button>
                </Tooltip>
              )}
              <Tooltip placement="left" overlay={<span>Approvals</span>}>
                <ApprovalButton location={location} />
              </Tooltip>
              <Tooltip placement="left" overlay={<span>Log Out</span>}>
                <Link to="/logout">Log Out</Link>
              </Tooltip>
            </MenuHeader>
          </Menu>

          {isNcrDialogOpen && (
            <NCRDialog
              heading="Non Conformance Entry."
              onClose={closeNcrDialog}
              title="Enter Non Conformance Information"
            />
          )}
          {isRequisitionDialogOpen && (
            <RequisitionDialog
              heading="Requisition"
              onClose={closeRequisitionDialog}
              title="Create Requisition"
            />
          )}
          {isViewOpenReqsModalOpen && (
            <ViewOpenReqsModal
              onClose={closeViewOpenReqsModal}
              company={company}
            />
          )}
          {isPODialogueOpen && (
            <POContainer
              onClose={togglePODialogue}
              onSave={togglePODialogue}
              currentUser={currentUser}
            />
          )}
          {isPODialogueOpen && (
            <POContainer
              onClose={togglePODialogue}
              onSave={togglePODialogue}
              currentUser={currentUser}
            />
          )}
          {isRequisitionDialogOpen && (
            <RequisitionDialog
              heading="Requisition"
              onClose={closeRequisitionDialog}
              onSave={closeRequisitionDialog}
              selectedProject={null}
              title={`Create Requisition`}
            />
          )}
        </Container>
      )}
      {isMobile && (
        <MenuIcon>
          <Tooltip placement="bottom" overlay={<span>Log Out</span>}>
            <Link to="/logout">
              <i className="fa fa-sign-out"></i>
            </Link>
          </Tooltip>
        </MenuIcon>
      )}
    </div>
  );
};

const Container = styled.div`
  position: relative;
  h4 {
    margin-right: 15px;
    font-size: 20px;
    color: #333;
  }
`;

const MenuIcon = styled.div`
  font-size: 24px;
  cursor: pointer;
  margin-right: 15px;
  z-index: 2;
  i {
    color: #333;
  }
`;

const MenuContainer = styled.div`
  position: relative;
`;

const Menu = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  transform: ${({open}) => (open ? 'translateX(0)' : 'translateX(100%)')};
  height: 100%;
  width: 20%;
  max-width: 300px;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease-in-out;
`;

const MenuHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  a,
  button {
    text-align: left;
    border: none;
    background: none;
    cursor: pointer;
    width: 100%;
    padding: 20px;
    &:hover {
      background-color: #ddd;
    }
  }
  a {
    margin-top: 10px;
    padding: 20px;
  }
`;

const container = compose(withSignout);

export default container(SettingsContainer);
